import type { QueryClient } from '@tanstack/react-query';
import { Navigate, type RouteObject } from 'react-router-dom';

import { Loader } from '@jane/shared/components';
import { load } from '@jane/shared/util';

const Brands = Loader(load(() => import('../features/Brands')));
const AllBrands = Loader(load(() => import('../features/AllBrands')));
const BrandSpecials = Loader(load(() => import('../features/BrandSpecials')));
const BrandDetail = Loader(load(() => import('../features/BrandDetail')));
const ProductDetail = Loader(load(() => import('../features/ProductDetail')));
const BrandSpecialDetails = Loader(
  load(() => import('../features/BrandSpecialDetails'))
);

export const brandsRoutes = (queryClient: QueryClient): RouteObject[] => [
  { element: <Brands />, path: 'brands' },
  {
    path: 'brands/all',
    element: <AllBrands />,
  },
  {
    path: 'brands/specials',
    element: <Navigate replace to="/brands/janegold" />,
  },
  {
    path: 'brands/janegold',
    element: <BrandSpecials />,
  },
  {
    path: 'brands/:brand_id/:brand_name',
    element: <BrandDetail />,
  },
  {
    path: 'brands/:brand_id/:brand_name/specials/:special_id',
    element: <BrandSpecialDetails />,
  },
  {
    path: 'brands/:brand_id/:brand_name/products/:product_id/:slug',
    element: <ProductDetail />,
  },
];
