/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const JaneApp96 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5935_11673)">
      <rect x={3} y={3} width={122} height={122} rx={28.06} fill="#5E1FF0" />
      <path
        d="M-21.4607 117.817C-24.2913 97.5813 -23.4724 64.7324 2.99722 38.2628C37.0077 4.25229 94.8559 8.11392 125.365 38.6226C155.882 69.1404 164.619 108.194 161.787 166.013C158.977 223.645 151.359 236.784 139.014 278.025C97.4966 290.646 84.3484 298.274 26.7255 301.074C-31.0936 303.906 -70.1372 295.16 -100.655 264.642C-131.164 234.134 -135.035 176.295 -101.015 142.275C-74.5548 115.815 -41.6964 114.986 -21.4607 117.817Z"
        fill="#8E00E6"
      />
      <path
        d="M206.323 163.746C214.29 144.94 221.997 113.011 203.287 80.6052C179.247 38.967 122.395 27.7274 85.0472 49.2904C47.6878 70.8598 29.1502 106.306 16.9286 162.865C4.73341 219.238 8.69012 233.895 9.94147 276.909C46.7623 299.838 57.4839 310.604 112.395 328.218C167.487 345.913 207.447 347.571 244.806 326.001C282.154 304.438 300.853 249.595 276.807 207.945C258.104 175.55 226.593 166.249 206.323 163.746Z"
        fill="#CE349A"
      />
      <path
        d="M-71.2981 104.879C-81.7076 91.0845 -101.843 71.9299 -133.505 71.9299C-174.186 71.9299 -206.474 108.834 -206.474 145.323C-206.474 181.824 -188.343 210.403 -152.069 243.287C-115.92 276.072 -103.506 279.373 -71.4576 296.654C-39.0787 279.373 -26.6533 276.072 9.48398 243.287C45.7579 210.403 63.8779 181.824 63.8779 145.323C63.8779 108.834 31.6014 71.9299 -9.0914 71.9299C-40.7414 71.9299 -60.8886 91.0845 -71.2981 104.879Z"
        fill="#FF5500"
      />
      <path
        d="M64.183 43.718C61.4914 40.1474 56.2848 35.1892 48.0979 35.1892C37.5788 35.1892 29.23 44.7418 29.23 54.1871C29.23 63.6354 33.9183 71.0332 43.2978 79.545C52.6449 88.0315 55.8548 88.8861 64.1417 93.3592C72.5141 88.8861 75.727 88.0315 85.0711 79.545C94.4506 71.0332 99.136 63.6354 99.136 54.1871C99.136 44.7418 90.7901 35.1892 80.268 35.1892C72.0841 35.1892 66.8746 40.1474 64.183 43.718Z"
        fill="#FFC220"
      />
    </g>
    <rect
      x={1.841}
      y={1.841}
      width={124.318}
      height={124.318}
      rx={29.219}
      stroke="#5E1FF0"
      strokeWidth={2.318}
    />
    <rect
      x={1.841}
      y={1.841}
      width={124.318}
      height={124.318}
      rx={29.219}
      stroke="white"
      strokeOpacity={0.8}
      strokeWidth={2.318}
    />
    <defs>
      <clipPath>
        <rect x={3} y={3} width={122} height={122} rx={28.06} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  xxxl: JaneApp96,
  xxl: JaneApp96,
  xl: JaneApp96,
  lg: JaneApp96,
  md: JaneApp96,
  sm: JaneApp96,
};

export const JaneAppIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'jane-app-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
