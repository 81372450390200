import type { QueryClient } from '@tanstack/react-query';
import type { RouteObject } from 'react-router-dom';

import { Loader } from '@jane/shared/components';
import { load } from '@jane/shared/util';

const StoreSpecials = Loader(load(() => import('../features/StoreSpecials')));
const StoreDetailReviews = Loader(
  load(() => import('../features/StoreDetailReviews'))
);
const Menu = Loader(
  load(() => import('../features/Menu')),
  'menu'
);
const MenuFeatured = Loader(
  load(() => import('../features/MenuFeatured')),
  'menu'
);
const MenuProductDetail = Loader(
  load(() => import('../features/MenuProductDetail')),
  'menu-product-detail'
);
const MenuProducts = Loader(load(() => import('../features/MenuProducts')));
const MenuSpecialDetail = Loader(
  load(() => import('../features/MenuSpecialDetail'))
);
const MenuSpecials = Loader(load(() => import('../features/MenuSpecials')));
const SpecialDetail = Loader(load(() => import('../features/SpecialDetail')));

const basePath = 'stores/:store_id/:slug?';

export const storeRoutes = (queryClient: QueryClient): RouteObject[] => [
  {
    element: <Menu />,
    path: basePath,
    children: [
      {
        index: true,
        element: <MenuFeatured />,
      },
    ],
  },
  {
    path: `${basePath}/menu`,
    element: <Menu />,
    children: [
      {
        index: true,
        element: <MenuFeatured />,
      },
      {
        path: 'featured',
        element: <MenuFeatured restoreScroll />,
      },
      {
        path: ':category',
        element: <MenuProducts restoreScroll />,
      },
      {
        path: ':category/:featured_category',
        element: <MenuProducts restoreScroll />,
      },
      {
        path: 'specials',
        element: <MenuSpecials restoreScroll />,
      },
      {
        path: 'specials/:special_id/:slug',
        element: <MenuSpecialDetail />,
      },
    ],
  },
  {
    path: `${basePath}/menu/products/:product_id/:slug`,
    element: <MenuProductDetail />,
  },
  {
    path: `${basePath}/products/:product_id/:slug`,
    element: <MenuProductDetail />,
  },
  {
    path: `${basePath}/reviews`,
    element: <StoreDetailReviews />,
  },
  {
    path: `${basePath}/specials`,
    element: <StoreSpecials />,
  },
  {
    path: `${basePath}/specials/:special_id/:slug`,
    element: <SpecialDetail />,
  },
];
