import type { QueryClient } from '@tanstack/react-query';
import { Navigate, type RouteObject } from 'react-router-dom';

import { Loader } from '@jane/shared/components';
import { load } from '@jane/shared/util';

const Home = Loader(load(() => import('../features/Home')));
const Directory = Loader(load(() => import('../features/Directory')));
const Category = Loader(load(() => import('../features/Category')));
const Dispensaries = Loader(load(() => import('../features/Dispensaries')));
const ReservationReceipt = Loader(
  load(() => import('../features/ReservationReceipt'))
);
const Invoice = Loader(load(() => import('../features/Invoice')));

const ADAPolicy = Loader(load(() => import('../features/ADAPolicy')));
const TermsOfUse = Loader(load(() => import('../features/TermsOfUse')));
const AdPolicy = Loader(load(() => import('../features/AdPolicy')));
const PrivacyPolicy = Loader(load(() => import('../features/PrivacyPolicy')));
const DoNotSellMyInformation = Loader(
  load(() => import('../features/DoNotSellMyInformation'))
);
const DoNotSellMyInformationVerification = Loader(
  load(() => import('../features/DoNotSellMyInformationVerification'))
);
const CurbsideByQRCode = Loader(
  load(() => import('../features/CurbsideByQRCode'))
);
const CurbsideConfirmationPage = Loader(
  load(() => import('../features/CurbsideConfirmationPage'))
);

export const gatedBaseRoutes = (queryClient: QueryClient): RouteObject[] => [
  { path: '/', element: <Home /> },
  {
    path: 'categories/:category',
    element: <Category />,
  },
  {
    path: 'directory',
    element: <Directory />,
  },
  {
    path: 'invoice/:invoice_uuid',
    element: <Invoice />,
  },
  {
    path: 'reservation_receipt/:reservation_id',
    element: <ReservationReceipt />,
  },
  {
    path: 'stores',
    element: <Dispensaries />,
  },
];

export const nonGatedBaseRoutes = (queryClient: QueryClient): RouteObject[] => [
  {
    path: 'ad-terms',
    element: <Navigate to="/advertising-policy" />,
  },
  {
    path: 'ada',
    element: <ADAPolicy />,
  },
  {
    path: 'advertising-policy',
    element: <AdPolicy />,
  },
  {
    path: 'advertising-terms',
    element: <TermsOfUse applicationName="advertising" />,
  },
  {
    path: 'brands-terms',
    element: <TermsOfUse applicationName="brands" />,
  },
  {
    path: 'cash-back-program-terms',
    element: <TermsOfUse applicationName="cash_back_program" />,
  },
  {
    path: 'curbside-by-qr-code/:store_id',
    element: <CurbsideByQRCode />,
  },
  {
    path: 'curbside-by-qr-code/:store_id/confirmation',
    element: <CurbsideConfirmationPage />,
  },
  {
    path: 'data-terms',
    element: <TermsOfUse applicationName="data" />,
  },
  {
    path: 'dispensary-terms',
    element: <TermsOfUse applicationName="business" />,
  },
  {
    path: 'do-not-sell-my-information',
    element: <DoNotSellMyInformation />,
  },
  {
    path: 'do-not-sell-my-information/verify/:token',
    element: <DoNotSellMyInformationVerification />,
  },
  {
    path: 'policy',
    element: <Navigate to="/privacy-policy" />,
  },
  {
    path: 'pos-pay-terms',
    element: <Navigate to="/pos-terms" />,
  },
  {
    path: 'pos-terms',
    element: <TermsOfUse applicationName="pos" />,
  },
  {
    path: 'privacy-policy',
    element: <PrivacyPolicy applicationName="customer" />,
  },
  {
    path: 'terms',
    element: <TermsOfUse applicationName="customer" />,
  },
  {
    path: 'dm-sdk-terms-of-service',
    element: <TermsOfUse applicationName="dm_sdk" />,
  },
];
