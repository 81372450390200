import type { QueryClient } from '@tanstack/react-query';
import type { RouteObject } from 'react-router-dom';

import { RequiresAuthentication } from '@jane/monolith/marketplace';
import { Loader } from '@jane/shared/components';
import { load } from '@jane/shared/util';

const Checkout = Loader(load(() => import('../features/Checkout')));

const Cart = Loader(load(() => import('../features/Cart')));

export const cartRoutes = (queryClient: QueryClient): RouteObject[] => [
  { path: 'cart', element: <Cart /> },
  {
    path: 'cart/checkout',
    element: (
      <RequiresAuthentication>
        <Checkout />
      </RequiresAuthentication>
    ),
  },
  {
    path: 'cart/fromEmbedded',
    element: <Cart />,
  },
  {
    path: 'cart/guest_checkout',
    element: <Checkout />,
  },
];
