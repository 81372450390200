/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const SearchFilled24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Search filled 24">
      <path
        fill="#5E1FF0"
        fillRule="evenodd"
        d="m15.791 18.62 2.795 2.794a2 2 0 1 0 2.828-2.828L18.62 15.79a9 9 0 1 0-2.828 2.828ZM16 11a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
        className="Fill-Main"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: SearchFilled24,
  sm: SearchFilled24,
  lg: SearchFilled24,
  xl: SearchFilled24,
  xxl: SearchFilled24,
  xxxl: SearchFilled24,
};

export const SearchFilledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'search-filled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
