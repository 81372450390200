import styled from '@emotion/styled';

import { Box, Flex, Typography } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

export const FooterContainer = styled(Flex)({
  ...spacing({ px: 24, py: 48 }),
  [mediaQueries.tablet('min')]: {
    ...spacing({ px: 64, py: 56 }),
  },
});

export const FooterColumnStyled = styled(Box)({
  minWidth: '150px',
  flexGrow: 1,
  [mediaQueries.tablet('min')]: {
    flexGrow: 0,
  },
  [mediaQueries.desktop('sm', 'min')]: {
    flexGrow: 0,
  },
});

export const FooterLinkStyled = styled(Typography)(
  ({ hasMarginRight }: { hasMarginRight: boolean }) => {
    if (hasMarginRight) {
      return {
        ...spacing({ mr: 24 }),
        [mediaQueries.tablet('min')]: {
          ...spacing({ mr: 40 }),
        },
      };
    }
    return {};
  }
);

export const FooterSectionLinks = styled(Flex)({
  ...spacing({ mb: 0 }),
  [mediaQueries.tablet('min')]: {
    ...spacing({ mb: 48 }),
  },
});

export const FooterSectionLogo = styled(Flex)({
  flex: '0 0 100%',
});

export const FooterSectionLegal = styled(Flex)({
  flex: '0 0 100%',
  textAlign: 'center',
  ...spacing({ mb: 32, mt: 48 }),
  [mediaQueries.tablet('min')]: {
    ...spacing({ mt: 32, mb: 0 }),
  },
});

export const FooterSectionSoc2 = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  position: 'unset',
  width: '100%',
  [mediaQueries.desktop('sm', 'min')]: {
    position: 'absolute',
    bottom: '40px',
    right: '40px',
    width: 'auto',
  },
});
