import type { QueryClient } from '@tanstack/react-query';
import type { RouteObject } from 'react-router-dom';

import { RequiresAuthentication } from '@jane/monolith/marketplace';
import { Loader } from '@jane/shared/components';
import { load } from '@jane/shared/util';

const Profile = Loader(load(() => import('../features/Profile')));
const ProfileEdit = Loader(load(() => import('../features/ProfileEdit')));
const ProfileChangePassword = Loader(
  load(() => import('../features/ProfileChangePassword'))
);
const Identification = Loader(load(() => import('../features/Identification')));
const ReservationHistory = Loader(
  load(() => import('../features/ReservationHistory'))
);
const ReservationDetail = Loader(
  load(() => import('../features/ReservationDetail'))
);
const ContactUs = Loader(load(() => import('../features/ContactUs')));

const ProfileRoot = () => (
  <RequiresAuthentication>
    <Profile marketplace />
  </RequiresAuthentication>
);

export const profileRoutes = (queryClient: QueryClient): RouteObject[] => [
  {
    element: <ProfileRoot />,
    path: 'profile',
    children: [
      {
        path: 'contactus',
        element: <ContactUs />,
      },
      {
        path: 'edit',
        element: <ProfileEdit />,
      },
      {
        path: 'identification',
        element: <Identification />,
      },
      {
        path: 'password',
        element: <ProfileChangePassword />,
      },
      {
        path: 'reservations',
        element: <ReservationHistory />,
      },
      {
        path: 'reservations/:reservation_id',
        element: <ReservationDetail />,
      },
    ],
  },
];
