import { useLocation, useNavigate } from 'react-router-dom';

import { userLocationToParams } from '@jane/search/util';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { NavBarTapSection } from '@jane/shared-ecomm/tracking';
import { useShouldShowGold } from '@jane/shared/hooks';
import {
  Box,
  ChevronDownIcon,
  Flex,
  HeartFilledIcon,
  JaneAppIcon,
  Link,
  List,
  MenuFilledIcon,
  Popover,
  Typography,
} from '@jane/shared/reefer';
import type { ColorKey, PopoverContextProps } from '@jane/shared/reefer';

import { MediaQuery } from '../../common/providers/mediaQueryProvider';
import { paths } from '../../lib/routes';

interface HeaderNavigationProps {
  color?: ColorKey;
  onClearSearch: () => void;
  onTrackTap: (navBarTap: NavBarTapSection) => void;
  showLogo?: boolean;
  toggleOverlay: (arg: boolean) => void;
}

const PopoverTarget = ({ color, children }) => (
  <Flex alignItems="center" p={12} css={{ '&:hover': { cursor: 'pointer' } }}>
    <Typography color={color} variant="body-bold" branded>
      {children}
    </Typography>
    <ChevronDownIcon color={color} size="sm" ml={8} />
  </Flex>
);

type DesktopNavProps = Pick<
  HeaderNavigationProps,
  'color' | 'onClearSearch' | 'onTrackTap'
>;

const ShopNowPopover = ({
  color,
  onClearSearch,
  onTrackTap,
}: DesktopNavProps) => {
  const {
    userLocation: { cityState, countryCode },
  } = useUserPreferences();
  const userLocationPath = userLocationToParams(cityState, countryCode);
  const showGold = useShouldShowGold();

  return (
    <Popover
      alignment={{ vertical: null }}
      label="shop now menu"
      openOn="hover"
      target={<PopoverTarget color={color}>Shop now</PopoverTarget>}
    >
      {({ closePopover }: PopoverContextProps) => (
        <Popover.Content label="shop now">
          <List label="shop now menu items">
            <List.Item>
              <Typography branded variant="body-bold">
                <Link
                  onClick={() => {
                    onClearSearch();
                    closePopover();
                    onTrackTap(NavBarTapSection.Products);
                  }}
                  to={paths.products()}
                  variant="minimal"
                >
                  Products
                </Link>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography color={color} branded variant="body-bold">
                <Link
                  onClick={() => {
                    onClearSearch();
                    closePopover();
                    onTrackTap(NavBarTapSection.Stores);
                  }}
                  to={
                    userLocationPath
                      ? paths.dispensaries({
                          country: userLocationPath.country,
                          state: userLocationPath.state,
                          city: userLocationPath.city,
                        })
                      : paths.dispensaries({})
                  }
                  variant="minimal"
                >
                  Dispensaries
                </Link>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography color={color} branded variant="body-bold">
                <Link
                  onClick={() => {
                    onClearSearch();
                    closePopover();
                    onTrackTap(NavBarTapSection.Brands);
                  }}
                  to={paths.brandsLanding()}
                  variant="minimal"
                >
                  Brands
                </Link>
              </Typography>
            </List.Item>
            {showGold && (
              <List.Item>
                <Typography branded variant="body-bold">
                  <Link
                    onClick={() => {
                      onClearSearch();
                      closePopover();
                      onTrackTap(NavBarTapSection.JaneGold);
                    }}
                    to={paths.brandJaneGold()}
                    variant="minimal"
                  >
                    Jane Gold
                  </Link>
                </Typography>
              </List.Item>
            )}

            <Popover.Divider />

            <List.Item>
              <Typography as="span" color={color} branded variant="body-bold">
                <Link
                  onClick={() => {
                    onClearSearch();
                    closePopover();
                    onTrackTap(NavBarTapSection.DownloadApp);
                  }}
                  href="https://iheartjane.app.link/marketplace"
                  variant="minimal"
                >
                  <Flex>
                    <JaneAppIcon mr={8} altText="Get iOS app" />
                    Get iOS app
                  </Flex>
                </Link>
              </Typography>
            </List.Item>
          </List>
        </Popover.Content>
      )}
    </Popover>
  );
};

type ForBusinessesPopoverProps = Pick<
  HeaderNavigationProps,
  'color' | 'onTrackTap'
>;

const ForBusinessesPopover = ({
  color,
  onTrackTap,
}: ForBusinessesPopoverProps) => (
  <Popover
    alignment={{ vertical: null }}
    label="for businesses menu"
    openOn="hover"
    target={<PopoverTarget color={color}>For businesses</PopoverTarget>}
  >
    <Popover.Content label="for businesses">
      <Flex>
        <Box mr={56} width="max-content">
          <List label="business types">
            <List.Item>
              <Typography branded variant="mini" color="grays-mid">
                Business types
              </Typography>
            </List.Item>

            <List.Item>
              <Typography branded variant="body-bold">
                <Link
                  href="http://iheartjane.com/overview"
                  onClick={() => onTrackTap(NavBarTapSection.BusinessOverview)}
                  variant="minimal"
                >
                  Overview
                </Link>
              </Typography>
            </List.Item>

            <List.Item>
              <Typography branded variant="body-bold">
                <Link
                  href="http://iheartjane.com/dispensaries-overview"
                  onClick={() =>
                    onTrackTap(NavBarTapSection.BusinessDispensaries)
                  }
                  variant="minimal"
                >
                  Dispensaries
                </Link>
              </Typography>
            </List.Item>

            <List.Item>
              <Typography branded variant="body-bold">
                <Link
                  href="http://iheartjane.com/brands-overview"
                  onClick={() => onTrackTap(NavBarTapSection.BusinessBrands)}
                  variant="minimal"
                >
                  Brands
                </Link>
              </Typography>
            </List.Item>
          </List>
        </Box>

        <Box mr={56} width="max-content">
          <List label="business solutions" pl={24}>
            <List.Item>
              <Typography branded variant="mini" color="grays-mid">
                Solutions
              </Typography>
            </List.Item>

            <List.Item>
              <Typography branded variant="body-bold">
                <Link
                  href="http://iheartjane.com/payments"
                  onClick={() => onTrackTap(NavBarTapSection.BusinessPayments)}
                  variant="minimal"
                >
                  Payments
                </Link>
              </Typography>
            </List.Item>

            <List.Item>
              <Typography branded variant="body-bold">
                <Link
                  href="http://iheartjane.com/ecommerce"
                  onClick={() => onTrackTap(NavBarTapSection.BusinessEcomm)}
                  variant="minimal"
                >
                  Ecommerce
                </Link>
              </Typography>
            </List.Item>

            <List.Item>
              <Typography branded variant="body-bold">
                <Link
                  href="http://iheartjane.com/pos"
                  onClick={() => onTrackTap(NavBarTapSection.BusinessPos)}
                  variant="minimal"
                >
                  Point of Sale
                </Link>
              </Typography>
            </List.Item>

            <List.Item>
              <Typography branded variant="body-bold">
                <Link
                  href="http://iheartjane.com/kiosk"
                  onClick={() => onTrackTap(NavBarTapSection.BusinessKiosk)}
                  variant="minimal"
                >
                  Kiosk
                </Link>
              </Typography>
            </List.Item>

            <List.Item>
              <Typography branded variant="body-bold">
                <Link
                  href="http://iheartjane.com/media"
                  onClick={() => onTrackTap(NavBarTapSection.BusinessMedia)}
                  variant="minimal"
                >
                  Media
                </Link>
              </Typography>
            </List.Item>
          </List>
        </Box>
      </Flex>
    </Popover.Content>
  </Popover>
);

const DesktopNav = ({ color, onClearSearch, onTrackTap }: DesktopNavProps) => {
  const location = useLocation();
  const {
    userLocation: { cityState, countryCode },
  } = useUserPreferences();
  const userLocationPath = userLocationToParams(cityState, countryCode);
  const showGold = useShouldShowGold();

  if (location.pathname === '/') {
    return (
      <Flex alignItems="center" minHeight="100%">
        <Flex minWidth={318} alignItems="center">
          <ShopNowPopover
            color={color}
            onClearSearch={onClearSearch}
            onTrackTap={onTrackTap}
          />
          <ForBusinessesPopover color={color} onTrackTap={onTrackTap} />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex alignItems="center" minHeight="100%">
      <Flex minWidth={318} alignItems="center">
        <Typography branded variant="body-bold">
          <Link
            onClick={() => {
              onClearSearch();
              onTrackTap(NavBarTapSection.Products);
            }}
            to={paths.products()}
            variant="minimal"
            mx={12}
          >
            Products
          </Link>
        </Typography>
        <Typography color={color} branded variant="body-bold">
          <Link
            onClick={() => {
              onClearSearch();
              onTrackTap(NavBarTapSection.Stores);
            }}
            to={
              userLocationPath
                ? paths.dispensaries({
                    country: userLocationPath.country,
                    state: userLocationPath.state,
                    city: userLocationPath.city,
                  })
                : paths.dispensaries({})
            }
            variant="minimal"
            mx={12}
          >
            Dispensaries
          </Link>
        </Typography>
        <Typography color={color} branded variant="body-bold">
          <Link
            onClick={() => {
              onClearSearch();
              onTrackTap(NavBarTapSection.Brands);
            }}
            to={paths.brandsLanding()}
            variant="minimal"
            mx={12}
          >
            Brands
          </Link>
        </Typography>
        {showGold && (
          <Typography color={color} branded variant="body-bold">
            <Link
              onClick={() => {
                onClearSearch();
                onTrackTap(NavBarTapSection.JaneGold);
              }}
              to={paths.brandJaneGold()}
              variant="minimal"
              mx={12}
            >
              Jane Gold
            </Link>
          </Typography>
        )}
      </Flex>
    </Flex>
  );
};

type MobileNavProps = Pick<
  HeaderNavigationProps,
  'color' | 'onTrackTap' | 'showLogo' | 'toggleOverlay'
>;

const MobileNav = ({
  color,
  showLogo,
  onTrackTap,
  toggleOverlay,
}: MobileNavProps) => {
  const navigate = useNavigate();

  const onLogoClick = () => {
    onTrackTap(NavBarTapSection.Logo);
    navigate(paths.root());
  };

  return (
    <Flex alignItems="center" mr={8}>
      <div>
        <MenuFilledIcon
          color={color}
          m={12}
          onClick={() => {
            toggleOverlay(true);
          }}
          altText="Open menu"
        />
      </div>

      {showLogo && (
        <div>
          <HeartFilledIcon
            m={12}
            altText="Navigate to Homepage"
            onClick={onLogoClick}
          />
        </div>
      )}
    </Flex>
  );
};

const HeaderNavigation = ({
  color,
  showLogo = true,
  toggleOverlay,
  onClearSearch,
  onTrackTap,
}: HeaderNavigationProps) => (
  <MediaQuery>
    {(media) =>
      media.desktop ? (
        <DesktopNav
          color={color}
          onClearSearch={onClearSearch}
          onTrackTap={onTrackTap}
        />
      ) : (
        <MobileNav
          color={color}
          showLogo={showLogo}
          toggleOverlay={toggleOverlay}
          onTrackTap={onTrackTap}
        />
      )
    }
  </MediaQuery>
);

export default HeaderNavigation;
