import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import { userLocationToParams } from '@jane/search/util';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { NavBarTapSection } from '@jane/shared-ecomm/tracking';
import { useShouldShowGold } from '@jane/shared/hooks';
import {
  Box,
  DismissIcon,
  Flex,
  JaneAppIcon,
  JaneLogo,
  Link,
  Modal,
  Typography,
} from '@jane/shared/reefer';

import { paths } from '../../lib/routes';

interface NavLinksModalProps {
  onClose: () => void;
  onTrackTap: (navBarTap: NavBarTapSection) => void;
}

const NavLinksContainer = styled.div(({ theme }) => ({
  width: '100%',
  background: theme.colors.grays.white,
}));

interface NavLinkProps {
  children: ReactNode;
  href?: string;
  onClick: () => void;
  path?: string;
}

const NavLink = ({ href, onClick, path, children }: NavLinkProps) => (
  <Box width="100%" py={16}>
    <Link
      branded
      onClick={onClick}
      href={href}
      to={path}
      typography="title-bold"
      color="grays-black"
      variant="minimal"
    >
      {children}
    </Link>
  </Box>
);

const SubHeader = ({ children }) => (
  <Box my={12}>
    <Typography color="grays-mid" branded variant="body-bold">
      {children}
    </Typography>
  </Box>
);

const NavLinksModal = ({ onClose, onTrackTap }: NavLinksModalProps) => {
  const {
    userLocation: { cityState, countryCode },
  } = useUserPreferences();
  const userLocationPath = userLocationToParams(cityState, countryCode);
  const showGold = useShouldShowGold();

  return (
    <NavLinksContainer>
      <Flex
        as="button"
        pb={24}
        pr={24}
        onClick={onClose}
        ariaLabel="Close menu"
      >
        <DismissIcon />
      </Flex>
      <Flex width="100%" justifyContent="center" alignItems="center">
        <Link
          onClick={() => {
            onClose();
            onTrackTap(NavBarTapSection.Logo);
          }}
          to={paths.root()}
          variant="minimal"
        >
          <JaneLogo size="sm" />
        </Link>
      </Flex>
      <SubHeader>Shop now</SubHeader>
      <NavLink
        onClick={() => {
          onClose();
          onTrackTap(NavBarTapSection.Products);
        }}
        path={paths.products()}
      >
        Products
      </NavLink>
      <NavLink
        onClick={() => {
          onClose();
          onTrackTap(NavBarTapSection.Stores);
        }}
        path={
          userLocationPath
            ? paths.dispensaries({
                country: userLocationPath.country,
                state: userLocationPath.state,
                city: userLocationPath.city,
              })
            : paths.dispensaries({})
        }
      >
        Dispensaries
      </NavLink>
      <NavLink
        onClick={() => {
          onClose();
          onTrackTap(NavBarTapSection.Brands);
        }}
        path={paths.brandsLanding()}
      >
        Brands
      </NavLink>
      {showGold && (
        <NavLink
          onClick={() => {
            onClose();
            onTrackTap(NavBarTapSection.JaneGold);
          }}
          path={paths.brandJaneGold()}
        >
          Jane Gold
        </NavLink>
      )}
      <NavLink
        onClick={() => {
          onClose();
          onTrackTap(NavBarTapSection.DownloadApp);
        }}
        href="https://iheartjane.app.link/marketplace"
      >
        <Flex alignItems="center">
          <JaneAppIcon mr={20} size="xl" altText="Get iOS app" />
          Get iOS app
        </Flex>
      </NavLink>

      <Modal.ContentDivider />

      <SubHeader>For businesses</SubHeader>
      <NavLink
        onClick={() => {
          onClose();
          onTrackTap(NavBarTapSection.BusinessOverview);
        }}
        href="http://iheartjane.com/overview"
      >
        Overview
      </NavLink>
      <NavLink
        onClick={() => {
          onClose();
          onTrackTap(NavBarTapSection.BusinessDispensaries);
        }}
        href="http://iheartjane.com/dispensaries-overview"
      >
        Dispensaries
      </NavLink>
      <NavLink
        onClick={() => {
          onClose();
          onTrackTap(NavBarTapSection.BusinessBrands);
        }}
        href="http://iheartjane.com/brands-overview"
      >
        Brands
      </NavLink>

      <Modal.ContentDivider />

      <SubHeader>Solutions</SubHeader>
      <NavLink
        onClick={() => {
          onClose();
          onTrackTap(NavBarTapSection.BusinessPayments);
        }}
        href="http://iheartjane.com/payments"
      >
        Payments
      </NavLink>
      <NavLink
        onClick={() => {
          onClose();
          onTrackTap(NavBarTapSection.BusinessEcomm);
        }}
        href="http://iheartjane.com/ecommerce"
      >
        Ecommerce
      </NavLink>
      <NavLink
        onClick={() => {
          onClose();
          onTrackTap(NavBarTapSection.BusinessPos);
        }}
        href="http://iheartjane.com/pos"
      >
        Point of Sale
      </NavLink>
      <NavLink
        onClick={() => {
          onClose();
          onTrackTap(NavBarTapSection.BusinessKiosk);
        }}
        href="http://iheartjane.com/kiosk"
      >
        Kiosk
      </NavLink>
      <NavLink
        onClick={() => {
          onClose();
          onTrackTap(NavBarTapSection.BusinessMedia);
        }}
        href="http://iheartjane.com/media"
      >
        Media
      </NavLink>
    </NavLinksContainer>
  );
};

export default NavLinksModal;
