import { useTheme } from '@emotion/react';
import type { CSSObject, Theme } from '@emotion/react';
import { Link, useLocation } from 'react-router-dom';

import { HeaderSignInPrompt } from '@jane/shared-ecomm/components';
import { NavBarTapSection } from '@jane/shared-ecomm/tracking';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Box, Typography } from '@jane/shared/reefer';
import type { ColorKey } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { paths } from '../../lib/routes';

interface GuestUserActionsProps {
  color?: ColorKey;
  onTrackTap: (navBarTap: NavBarTapSection) => void;
}

const linkStyles = ({ theme }: { theme: Theme }): CSSObject => ({
  ...spacing({ m: 12 }),
  flex: '0 0 auto',
  position: 'relative',
  width: 46,
  color: theme.colors.grays.black,
});

const GuestUserActions = ({ color, onTrackTap }: GuestUserActionsProps) => {
  const myHigh = useFlag(FLAGS.myHighMenu);
  const location = useLocation();
  const theme = useTheme();

  const currentPath =
    location.pathname === '/login'
      ? '/'
      : `${location.pathname}${location.search || ''}`;

  if (myHigh)
    return (
      <Box m={12}>
        <HeaderSignInPrompt color={color} />
      </Box>
    );

  return (
    <Link
      state={{ nextPathname: currentPath }}
      onClick={() => onTrackTap(NavBarTapSection.Login)}
      to={paths.login()}
    >
      <Box css={linkStyles({ theme })}>
        <Typography color={color} branded variant="body-bold">
          Log in
        </Typography>
      </Box>
    </Link>
  );
};

export default GuestUserActions;
