import type { NavBarTapSection } from '@jane/shared-ecomm/tracking';
import type { ColorKey } from '@jane/shared/reefer';

import AuthenticatedUserActions from './authenticatedUserActions';
import GuestUserActions from './guestUserActions';

interface Props {
  color?: ColorKey;
  hasUser: boolean;
  onTrackTap: (navBarTap: NavBarTapSection) => void;
}

const UserActions = ({ color, hasUser, onTrackTap }: Props) =>
  hasUser ? (
    <AuthenticatedUserActions color={color} onTrackTap={onTrackTap} />
  ) : (
    <GuestUserActions color={color} onTrackTap={onTrackTap} />
  );

export default UserActions;
