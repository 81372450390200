import { useLocation, useNavigate } from 'react-router-dom';

import type { Location } from '@jane/shared/models';
import { Box, Flex, Modal, Typography } from '@jane/shared/reefer';

import Security from '../security';
import CTAButton from './ctaButton';
import type { LocationModalScreen } from './index';

interface Props {
  appPartner: string;
  disallowCloseModal: boolean;
  isMarketplace: boolean;
  onCloseModal: () => void;
  setShowScreen: (screen: LocationModalScreen) => void;
  unservedLocation: Location | null;
}

const NotInArea = ({
  appPartner,
  disallowCloseModal,
  isMarketplace,
  onCloseModal,
  setShowScreen,
  unservedLocation,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  if (unservedLocation == null) return null;

  const onDecline = () => {
    onCloseModal();
    navigate(`${location.pathname}?not_connected`, { replace: true });
  };

  return (
    <Modal.Content>
      <Flex flexDirection="column" width="100%">
        <Typography
          color="primary"
          as="div"
          branded
          textAlign="center"
          variant="title-bold"
        >
          Snap!
        </Typography>
        <Typography
          as="div"
          mt={12}
          mx={32}
          textAlign="center"
          variant="body-bold"
        >
          {`Looks like ${appPartner} isn't in your area yet.`}
        </Typography>
        {!disallowCloseModal && appPartner !== 'Jane' && (
          <CTAButton onClick={onDecline} text="Continue Browsing" />
        )}
        <Box mt={12}>
          <Security appPartner={appPartner} modal />
        </Box>
      </Flex>
    </Modal.Content>
  );
};

export default NotInArea;
