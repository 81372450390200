import styled from '@emotion/styled';

import { NavBarTapSection } from '@jane/shared-ecomm/tracking';
import {
  useInProgressReservations,
  useJaneUser,
  useUserBrandDiscounts,
} from '@jane/shared/data-access';
import { useShouldShowGold } from '@jane/shared/hooks';
import type { Store } from '@jane/shared/models';
import { useEcommApp } from '@jane/shared/providers';
import { Flex } from '@jane/shared/reefer';
import type { ColorKey } from '@jane/shared/reefer';

import { useCustomerSelector } from '../../customer/selectors';
import { paths } from '../../lib/routes';
import { get } from '../../redux-util/selectors';
import { AuthenticatedLink } from './authenticatedLink';
import ProfileButton from './profileButton';

const ProfileContainer = styled(Flex)({
  position: 'relative',
});

interface Props {
  color?: ColorKey;
  onTrackTap: (navBarTap: NavBarTapSection) => void;
}

const AuthenticatedUserActions = ({ color, onTrackTap }: Props) => {
  const { store } = useCustomerSelector(get('store'));
  const showJaneGold = useShouldShowGold({ store: store as Store });
  const { appMode } = useEcommApp();
  const { data: userData } = useJaneUser();

  const { data: inProgressReservations } = useInProgressReservations({
    storeId: appMode === 'default' ? undefined : (store as Store).id,
  });

  const { data: brandDiscounts } = useUserBrandDiscounts({
    enabled: showJaneGold && !!userData?.user?.authenticated,
    janeUserId: userData?.user?.id,
    status: 'awaiting_registration',
  });

  return (
    <AuthenticatedLink
      onClick={() => onTrackTap(NavBarTapSection.Account)}
      to={paths.profile()}
      ariaLabel="Profile"
    >
      <ProfileContainer alignItems="center">
        <ProfileButton
          color={color}
          inProgressReservations={inProgressReservations?.length || 0}
          pendingCashBack={brandDiscounts?.total || 0}
        />
      </ProfileContainer>
    </AuthenticatedLink>
  );
};

export default AuthenticatedUserActions;
