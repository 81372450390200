import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import type { FormEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Configure, Index, InstantSearch } from '@jane/search/components';
import { getAlgoliaClient } from '@jane/search/data-access';
import type { InfiniteHitsProvided, SearchState } from '@jane/search/types';
import { useQuery } from '@jane/shared-ecomm/hooks';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { config } from '@jane/shared/config';
import type { Spacing } from '@jane/shared/reefer';
import { useMobileMediaQuery, useTabletMediaQuery } from '@jane/shared/reefer';
import { mediaQueries, shadows, spacing } from '@jane/shared/reefer-emotion';

import { useCustomerSelector } from '../../customer/selectors';
import { useOutsideClickHandler } from '../../hooks/useOutsideClickHandler';
import {
  MENU_PRODUCTS_DEFAULT,
  PRODUCTS_DEFAULT,
  STORE_DEFAULT,
} from '../../lib/algoliaIndices';
import { paths } from '../../lib/routes';
import { get } from '../../redux-util/selectors';
import {
  HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from '../../types/headerConstants';
import type { SearchFormMode } from './form';
import { SEARCH_RESULTS_Z_INDEX } from './helper';
import TypeAheadProductSearchResults from './typeAheadProductSearchResults';
import TypeAheadSearchInput, {
  SEARCH_FORM_HEIGHT_LARGE,
  SEARCH_FORM_HEIGHT_XLARGE,
} from './typeAheadSearchInput';
import TypeAheadSearchResultsSection from './typeAheadSearchResultsSection';
import TypeAheadStoreSearchResults from './typeAheadStoreSearchResults';

const pulse = keyframes`
  0%  {transform: translate(-50%, 0) scale(.9);}
  33%  {transform: translate(-50%, 0) scale(1);}
  66%  {transform: translate(-50%, 0) scale(1.01);}
  100%  {transform: translate(-50%, 0) scale(1);}
`;

const pulseDesktop = keyframes`
  0%  {transform: scale(.9);}
  33%  {transform: scale(1);}
  66%  {transform: scale(1.01);}
  100%  {transform: scale(1);}
`;

const TypeAheadSearchResultsContainer = styled.div<{
  searchFormMode: SearchFormMode;
}>(
  ({ searchFormMode, theme }) => ({
    borderRadius: theme.borderRadius.sm,
    boxShadow: shadows.bar,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0) scale(1)',
    backgroundColor: theme.colors.grays.white,
    zIndex: SEARCH_RESULTS_Z_INDEX,
    overflow: 'auto',
    minWidth: searchFormMode === 'hero' ? 'none' : 475,
    width: '100%',
    maxHeight: '75vh',
    [mediaQueries.mobile('legacy', 'max')]: searchFormMode !== 'hero' && [
      {
        minWidth: 320,
        borderRadius: 0,
        WebkitOverflowScrolling: 'touch',
        maxHeight: 'calc(100vh - 64px)',
      },
    ],
  }),
  ({ searchFormMode }) => [
    searchFormMode === 'hero' && {
      top: SEARCH_FORM_HEIGHT_LARGE + 10,
      [mediaQueries.tablet('min')]: {
        top: SEARCH_FORM_HEIGHT_XLARGE + 10,
      },
    },
    searchFormMode === 'header' && {
      [mediaQueries.desktop('sm', 'max')]: {
        top: MOBILE_HEADER_HEIGHT,
        width: '100%',
      },
      [mediaQueries.desktop('sm', 'min')]: {
        minWidth: 303,
        top: HEADER_HEIGHT - 10,
        transform: 'none',
        right: 0,
        animation: pulseDesktop,
        left: 'unset',
        width: '50%',
      },
      [mediaQueries.mobile('legacy', 'max')]: [
        spacing({ pt: MOBILE_HEADER_HEIGHT as Spacing }),
        {
          top: 0,
        },
      ],
    },
  ]
);

const StyledDiv = styled.div({
  width: '100%',
});

interface Props {
  formId: string;
  placeHolder: string;
  searchFormMode: SearchFormMode;
}

interface RenderSearchHitsProps {
  hits: InfiniteHitsProvided['hits'];
  searchState: SearchState;
}

/* eslint-disable max-statements */
const TypeAheadSearch = ({ formId, placeHolder, searchFormMode }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const isMobile = useMobileMediaQuery({ size: 'legacy' });
  const isTablet = useTabletMediaQuery({});
  const searchContainerElement = useRef<HTMLDivElement>(null);
  const [searchFocused, setSearchFocused] = useState(false);
  const { userLocation } = useUserPreferences();
  const { janeDeviceId } = useCustomerSelector(get('application'));

  useOutsideClickHandler(searchContainerElement, () => {
    setSearchFocused(false);
  });

  const mobileOverlayShown = useRef(false);

  useEffect(() => {
    const onEscapeKeyPress = (event: KeyboardEvent) => {
      if (event.keyCode === 27) setSearchFocused(false);
    };
    document.addEventListener('keydown', onEscapeKeyPress, false);
    return () =>
      document.removeEventListener('keydown', onEscapeKeyPress, false);
  }, []);

  useEffect(() => {
    // If user is navigating with tab, we want the search to unfocus & collapse
    const onFocus = (event: FocusEvent) => {
      if (
        searchFocused &&
        !searchContainerElement.current?.contains(event.target as Node)
      ) {
        setSearchFocused(false);
      }
    };
    document.addEventListener('focusin', onFocus, false);
    return () => document.removeEventListener('focusin', onFocus, false);
  }, [searchFocused, searchContainerElement.current]);

  const hideResultsDropdown = () => {
    setSearchFocused(false);
    const body = document.querySelector('body');
    body && body.classList.remove('ReactModal__Body--open');
    document.body.style.overflow = 'unset';
    mobileOverlayShown.current = false;
  };

  useEffect(() => {
    hideResultsDropdown();
  }, [location.pathname]);

  useEffect(() => {
    if (
      searchFocused &&
      !mobileOverlayShown.current &&
      isMobile &&
      searchFormMode === 'header'
    ) {
      const body = document.querySelector('body');
      body && body.classList.add('ReactModal__Body--open');
      document.body.style.overflow = 'hidden';
      mobileOverlayShown.current = true;
    }
    if (!searchFocused) {
      hideResultsDropdown();
    }
  }, [searchFocused]);

  const currentlyOnStorePage = location?.pathname?.includes('/dispensaries');

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>, searchQuery: string) => {
      event.preventDefault();
      setSearchFocused(false);

      navigate(
        currentlyOnStorePage
          ? paths.stores({ ...query, searchText: searchQuery })
          : paths.products({ ...query, searchText: searchQuery })
      );
    },
    [currentlyOnStorePage, setSearchFocused, location, navigate, query]
  );

  const shouldSearchMenuProducts = userLocation.coordinates;

  const productIndex = shouldSearchMenuProducts
    ? MENU_PRODUCTS_DEFAULT
    : PRODUCTS_DEFAULT;

  const storeIndex = STORE_DEFAULT;

  const nudgeTypeAheadResults = searchFormMode === 'header' && !isTablet;

  return (
    <InstantSearch
      searchClient={getAlgoliaClient(config)}
      indexName={PRODUCTS_DEFAULT}
    >
      <Configure hitsPerPage={6} userToken={janeDeviceId} />
      <StyledDiv ref={searchContainerElement}>
        <TypeAheadSearchInput
          mode={searchFormMode}
          searchType="global-search-input"
          onSubmit={onSubmit}
          formId={formId}
          placeholder={placeHolder}
          searchFocused={searchFocused}
          setSearchFocused={setSearchFocused}
        />
        {searchFocused && (
          <TypeAheadSearchResultsContainer
            css={{
              animation: !isMobile && `${pulse} 200ms ease`,
              //NOTE: Add this to the styled component when flag is removed
              marginTop: nudgeTypeAheadResults && -7,
            }}
            searchFormMode={searchFormMode}
          >
            <div css={css({ order: currentlyOnStorePage ? 2 : 1 })}>
              <Index indexName={productIndex}>
                <Configure
                  filters={
                    shouldSearchMenuProducts
                      ? 'store_specific_product:false'
                      : undefined
                  }
                />

                <TypeAheadSearchResultsSection
                  hitType="products"
                  hideResultsDropdown={hideResultsDropdown}
                  renderSearchHits={({
                    hits,
                    searchState,
                  }: RenderSearchHitsProps) => (
                    <TypeAheadProductSearchResults
                      hits={hits}
                      searchState={searchState}
                      setSearchFocused={setSearchFocused}
                      indexName={productIndex}
                    />
                  )}
                />
              </Index>
            </div>
            <div css={css({ order: currentlyOnStorePage ? 1 : 2 })}>
              <Index indexName={storeIndex}>
                <TypeAheadSearchResultsSection
                  hitType="dispensaries"
                  hideResultsDropdown={hideResultsDropdown}
                  renderSearchHits={({
                    hits,
                    searchState,
                  }: RenderSearchHitsProps) => (
                    <TypeAheadStoreSearchResults
                      hits={hits}
                      searchState={searchState}
                      setSearchFocused={setSearchFocused}
                      indexName={storeIndex}
                    />
                  )}
                />
              </Index>
            </div>
          </TypeAheadSearchResultsContainer>
        )}
      </StyledDiv>
    </InstantSearch>
  );
};

export default TypeAheadSearch;
