import { initialize, openSession } from '@braze/web-sdk';

import { config } from '@jane/shared/config';

const SDK_ENDPOINT = 'sdk.iad-02.braze.com';

export function initBraze() {
  // disable for local dev
  if (config.nodeEnv !== 'development') {
    initialize(config.brazeAPIKey, {
      baseUrl: SDK_ENDPOINT,
    });

    openSession();
  }
}
