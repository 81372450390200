import type { MouseEvent, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  hasUserSelector,
  isEmbeddedModeSelector,
  useCustomerSelector,
} from '../../customer/selectors';
import { paths } from '../../lib/routes';
import { get } from '../../redux-util/selectors';

interface Props {
  ariaLabel?: string;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: (...args: any[]) => void;
  to: string;
}

export const AuthenticatedLink = ({
  ariaLabel,
  children,
  onClick,
  to,
}: Props) => {
  const navigate = useNavigate();
  const [unauthenticatedAttempt, setUnauthenticatedAttempt] = useState(false);
  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);
  const customer = useCustomerSelector(get('customer'));
  const hasUser = hasUserSelector(customer);
  const { authenticated } = customer;

  const executeAction = () => {
    if (onClick) onClick();

    if (to) {
      return navigate(to);
    }
  };

  const handleClick = (
    e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    if (authenticated) {
      return executeAction();
    }

    e.preventDefault();
    setUnauthenticatedAttempt(true);

    if (isEmbeddedMode) {
      const path = hasUser ? 'embeddedLogin' : 'embeddedRegistration';
      return navigate(paths[path]());
    }

    return navigate(paths.login());
  };

  useEffect(() => {
    if (authenticated && unauthenticatedAttempt) {
      executeAction();
    }
  }, [authenticated]);

  return (
    <Link onClick={handleClick} to={to} aria-label={ariaLabel}>
      {children}
    </Link>
  );
};
