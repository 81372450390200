import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Flex, Typography } from '@jane/shared/reefer';

import { SvgIcon } from '../../component-library/svg-icon';
import { SearchHit } from './typeAheadSearchHit';

const SearchAllLink = styled.button();

const SearchIcon = styled(Flex)({
  lineHeight: 0,
});

interface Props {
  hitType: string;
  linkTo: string;
  searchQuery?: string;
  setSearchFocused: (arg: boolean) => void;
}

const TypeAheadSearchAllLink = ({
  linkTo,
  hitType,
  searchQuery,
  setSearchFocused,
}: Props) => {
  const theme = useTheme();

  return (
    <Link onClick={() => setSearchFocused(false)} to={linkTo}>
      <SearchHit>
        <SearchIcon
          justifyContent="center"
          alignItems="center"
          mr={12}
          width={40}
        >
          <SvgIcon icon="search" color={theme.colors.grays.black} size={18} />
        </SearchIcon>
        <SearchAllLink>
          <Typography variant="body-bold">{`Search all ${hitType} for "${searchQuery}"`}</Typography>
        </SearchAllLink>
      </SearchHit>
    </Link>
  );
};

export default TypeAheadSearchAllLink;
