import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useJWT } from '@jane/shared/auth';

import {
  isEmbeddedModeSelector,
  useCustomerSelector,
} from '../customer/selectors';
import { paths } from '../lib/routes';
import { get } from '../redux-util/selectors';

export const useRequireAuthenticatedCustomer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const customer = useCustomerSelector(get('customer'));

  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);
  const jwt = useJWT();

  useEffect(() => {
    if (customer.loading || (customer.authenticated && jwt)) {
      return;
    }

    const pathname = isEmbeddedMode ? paths.embeddedLogin() : paths.login();

    navigate(pathname, {
      state: { nextPathname: location.pathname },
      replace: true,
    });
  }, [customer.authenticated, customer.loading, isEmbeddedMode]);

  if (customer.loading || !customer.authenticated || !jwt) {
    return {
      loading: true,
      customer: null,
      jwt: null,
    } as const;
  }

  return {
    loading: false,
    customer,
    jwt,
  } as const;
};
