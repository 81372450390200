import {
  Flex,
  HalfStarIcon,
  StarFilledIcon,
  Typography,
} from '@jane/shared/reefer';

interface StarRatingProps {
  minimumRatings?: number | null;
  numberOfRatings?: number | null;
  rating?: number | null;
}

export const StarRating = ({
  minimumRatings,
  numberOfRatings,
  rating,
}: StarRatingProps) => {
  if (
    !numberOfRatings ||
    !rating ||
    (minimumRatings && minimumRatings > numberOfRatings)
  ) {
    return null;
  }

  return (
    <Flex alignItems="center">
      {[1, 2, 3, 4, 5].map((number) => {
        if (rating < number && rating > number - 0.6) {
          return <HalfStarIcon size="sm" key={number} />;
        }

        const starColor = rating >= number ? 'gold' : 'grays-light';

        return <StarFilledIcon size="sm" key={number} color={starColor} />;
      })}
      <Typography color="text-light" ml={4}>
        {numberOfRatings}
      </Typography>
    </Flex>
  );
};
