export const Soc2Image = () => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="96" height="96" rx="48" fill="#5E1FF0" />
    <rect width="96" height="96" rx="48" fill="black" fillOpacity="0.2" />
    <path
      d="M32.56 32L35.7 24.9H36.47L39.62 32H38.65L37.74 29.93H34.43L33.52 32H32.56ZM34.79 29.09H37.37L36.08 26.16L34.79 29.09ZM40.6177 32V24.9H41.5477V32H40.6177ZM42.9402 28.45C42.9402 26.39 44.5702 24.78 46.6102 24.78C47.9602 24.78 49.1102 25.45 49.7502 26.5L48.9702 26.97C48.4802 26.18 47.6502 25.66 46.6102 25.66C45.0602 25.66 43.8802 26.89 43.8802 28.45C43.8802 30.01 45.0602 31.24 46.6102 31.24C47.6502 31.24 48.4802 30.72 48.9702 29.93L49.7502 30.4C49.1102 31.45 47.9602 32.12 46.6102 32.12C44.5702 32.12 42.9402 30.51 42.9402 28.45ZM53.1491 24.9C54.6291 24.9 55.5891 25.88 55.5891 27.16C55.5891 28.44 54.6291 29.41 53.1491 29.41H51.9091V32H50.9791V24.9H53.1491ZM53.1491 28.55C54.1291 28.55 54.6691 27.95 54.6691 27.16C54.6691 26.37 54.1291 25.77 53.1491 25.77H51.9091V28.55H53.1491ZM55.6166 32L58.7566 24.9H59.5266L62.6766 32H61.7066L60.7966 29.93H57.4866L56.5766 32H55.6166ZM57.8466 29.09H60.4266L59.1366 26.16L57.8466 29.09Z"
      fill="#5E1FF0"
    />
    <path
      d="M32.56 32L35.7 24.9H36.47L39.62 32H38.65L37.74 29.93H34.43L33.52 32H32.56ZM34.79 29.09H37.37L36.08 26.16L34.79 29.09ZM40.6177 32V24.9H41.5477V32H40.6177ZM42.9402 28.45C42.9402 26.39 44.5702 24.78 46.6102 24.78C47.9602 24.78 49.1102 25.45 49.7502 26.5L48.9702 26.97C48.4802 26.18 47.6502 25.66 46.6102 25.66C45.0602 25.66 43.8802 26.89 43.8802 28.45C43.8802 30.01 45.0602 31.24 46.6102 31.24C47.6502 31.24 48.4802 30.72 48.9702 29.93L49.7502 30.4C49.1102 31.45 47.9602 32.12 46.6102 32.12C44.5702 32.12 42.9402 30.51 42.9402 28.45ZM53.1491 24.9C54.6291 24.9 55.5891 25.88 55.5891 27.16C55.5891 28.44 54.6291 29.41 53.1491 29.41H51.9091V32H50.9791V24.9H53.1491ZM53.1491 28.55C54.1291 28.55 54.6691 27.95 54.6691 27.16C54.6691 26.37 54.1291 25.77 53.1491 25.77H51.9091V28.55H53.1491ZM55.6166 32L58.7566 24.9H59.5266L62.6766 32H61.7066L60.7966 29.93H57.4866L56.5766 32H55.6166ZM57.8466 29.09H60.4266L59.1366 26.16L57.8466 29.09Z"
      fill="white"
      fillOpacity="0.8"
    />
    <path
      d="M24.932 52.16L26.708 50.672C27.268 51.52 28.244 52.016 29.172 52.016C30.084 52.016 30.692 51.568 30.692 50.912C30.692 50.272 30.228 49.824 29.108 49.44L28.148 49.104C26.324 48.464 25.348 47.408 25.348 45.856C25.348 43.712 26.98 42.448 29.188 42.448C30.58 42.448 31.796 42.928 32.772 44.016L31.236 45.6C30.692 44.944 29.988 44.624 29.204 44.624C28.42 44.624 27.684 44.96 27.684 45.632C27.684 46.32 28.244 46.624 29.444 47.056L30.356 47.392C32.02 48 33.06 49.056 33.06 50.768C33.044 52.8 31.428 54.192 29.076 54.192C27.268 54.192 25.748 53.408 24.932 52.16ZM45.9766 48.32C45.9766 51.568 43.3526 54.192 40.1046 54.192C36.8566 54.192 34.2326 51.568 34.2326 48.32C34.2326 45.072 36.8566 42.448 40.1046 42.448C43.3526 42.448 45.9766 45.072 45.9766 48.32ZM36.5686 48.32C36.5686 50.304 38.1206 51.952 40.1046 51.952C42.0886 51.952 43.6406 50.304 43.6406 48.32C43.6406 46.336 42.0886 44.688 40.1046 44.688C38.1206 44.688 36.5686 46.336 36.5686 48.32ZM47.1545 48.32C47.1545 45.072 49.7785 42.448 53.0265 42.448C55.3465 42.448 57.2985 43.648 58.2745 45.472L56.2585 46.592C55.5865 45.424 54.4985 44.688 53.0265 44.688C51.0425 44.688 49.4905 46.336 49.4905 48.32C49.4905 50.304 51.0425 51.952 53.0265 51.952C54.4985 51.952 55.5865 51.216 56.2585 50.048L58.2745 51.168C57.2985 52.992 55.3465 54.192 53.0265 54.192C49.7785 54.192 47.1545 51.568 47.1545 48.32ZM70.6031 51.84V54H62.8591V53.248C62.8591 50.704 64.8591 49.616 66.5231 48.416C67.5151 47.712 68.3151 47.008 68.3151 46.08C68.3151 45.248 67.7871 44.608 66.8111 44.608C65.8031 44.608 65.0831 45.296 65.0991 46.208H62.8911C62.8911 43.984 64.6031 42.448 66.8591 42.448C68.7791 42.448 70.5071 43.632 70.5071 45.952C70.5071 47.904 69.5471 48.896 68.1071 49.952C67.4031 50.48 66.2031 51.104 65.8991 51.84H70.6031Z"
      fill="white"
    />
    <path
      d="M26.38 71V64.78H24.17V63.9H29.5V64.78H27.29V71H26.38ZM32.5838 71V67.99L29.8238 63.9H30.9338L33.0438 67.06L35.1638 63.9H36.2738L33.5038 68V71H32.5838ZM39.5221 63.9C41.0021 63.9 41.9621 64.88 41.9621 66.16C41.9621 67.44 41.0021 68.41 39.5221 68.41H38.2821V71H37.3521V63.9H39.5221ZM39.5221 67.55C40.5021 67.55 41.0421 66.95 41.0421 66.16C41.0421 65.37 40.5021 64.77 39.5221 64.77H38.2821V67.55H39.5221ZM43.2115 71V63.9H47.3515V64.78H44.1415V66.93H46.7515V67.81H44.1415V70.12H47.3515V71H43.2115ZM51.7093 71V64.75H50.3393L51.0393 63.9H52.5693V71H51.7093ZM62.2931 71L61.0831 69.82C60.3431 70.69 59.7131 71.1 58.7931 71.1C57.4931 71.1 56.6131 70.23 56.6131 69.06C56.6131 68.03 57.3731 67.46 58.1231 67.03L58.1731 67C57.7531 66.59 57.3131 66.07 57.3131 65.39C57.3131 64.44 58.1131 63.78 59.1231 63.78C60.1731 63.78 60.8731 64.53 60.8731 65.43C60.8731 66.31 60.1931 66.81 59.5031 67.17L61.0131 68.65L61.8531 67.58H62.8531L61.5831 69.2L63.4231 71H62.2931ZM58.1431 65.41C58.1431 65.88 58.4831 66.2 58.9431 66.63L58.9931 66.61C59.5631 66.32 60.0231 66.03 60.0231 65.49C60.0231 64.93 59.6631 64.53 59.1031 64.53C58.5931 64.53 58.1431 64.86 58.1431 65.41ZM57.4831 69.07C57.4831 69.86 58.1331 70.32 58.8231 70.32C59.4631 70.32 59.9331 69.98 60.5231 69.28L58.7431 67.55L58.6131 67.63C57.9131 68 57.4831 68.39 57.4831 69.07ZM70.8869 70.17V71H66.2869V70.66C66.2869 69.14 67.6169 68.44 68.6669 67.66C69.4869 67.05 69.9269 66.68 69.9269 65.85C69.9269 65.11 69.3969 64.62 68.6269 64.62C67.8469 64.62 67.1669 65.1 67.1569 66.02H66.2769C66.2769 64.66 67.3069 63.78 68.6169 63.78C69.7969 63.78 70.8069 64.48 70.8069 65.86C70.8069 66.9 70.3269 67.5 69.1669 68.34C68.3469 68.94 67.5169 69.35 67.3469 70.17H70.8869Z"
      fill="#5E1FF0"
    />
    <path
      d="M26.38 71V64.78H24.17V63.9H29.5V64.78H27.29V71H26.38ZM32.5838 71V67.99L29.8238 63.9H30.9338L33.0438 67.06L35.1638 63.9H36.2738L33.5038 68V71H32.5838ZM39.5221 63.9C41.0021 63.9 41.9621 64.88 41.9621 66.16C41.9621 67.44 41.0021 68.41 39.5221 68.41H38.2821V71H37.3521V63.9H39.5221ZM39.5221 67.55C40.5021 67.55 41.0421 66.95 41.0421 66.16C41.0421 65.37 40.5021 64.77 39.5221 64.77H38.2821V67.55H39.5221ZM43.2115 71V63.9H47.3515V64.78H44.1415V66.93H46.7515V67.81H44.1415V70.12H47.3515V71H43.2115ZM51.7093 71V64.75H50.3393L51.0393 63.9H52.5693V71H51.7093ZM62.2931 71L61.0831 69.82C60.3431 70.69 59.7131 71.1 58.7931 71.1C57.4931 71.1 56.6131 70.23 56.6131 69.06C56.6131 68.03 57.3731 67.46 58.1231 67.03L58.1731 67C57.7531 66.59 57.3131 66.07 57.3131 65.39C57.3131 64.44 58.1131 63.78 59.1231 63.78C60.1731 63.78 60.8731 64.53 60.8731 65.43C60.8731 66.31 60.1931 66.81 59.5031 67.17L61.0131 68.65L61.8531 67.58H62.8531L61.5831 69.2L63.4231 71H62.2931ZM58.1431 65.41C58.1431 65.88 58.4831 66.2 58.9431 66.63L58.9931 66.61C59.5631 66.32 60.0231 66.03 60.0231 65.49C60.0231 64.93 59.6631 64.53 59.1031 64.53C58.5931 64.53 58.1431 64.86 58.1431 65.41ZM57.4831 69.07C57.4831 69.86 58.1331 70.32 58.8231 70.32C59.4631 70.32 59.9331 69.98 60.5231 69.28L58.7431 67.55L58.6131 67.63C57.9131 68 57.4831 68.39 57.4831 69.07ZM70.8869 70.17V71H66.2869V70.66C66.2869 69.14 67.6169 68.44 68.6669 67.66C69.4869 67.05 69.9269 66.68 69.9269 65.85C69.9269 65.11 69.3969 64.62 68.6269 64.62C67.8469 64.62 67.1669 65.1 67.1569 66.02H66.2769C66.2769 64.66 67.3069 63.78 68.6169 63.78C69.7969 63.78 70.8069 64.48 70.8069 65.86C70.8069 66.9 70.3269 67.5 69.1669 68.34C68.3469 68.94 67.5169 69.35 67.3469 70.17H70.8869Z"
      fill="white"
      fillOpacity="0.8"
    />
  </svg>
);
