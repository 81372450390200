import { ArrowLeftIcon, Button } from '@jane/shared/reefer';

interface Props {
  onClick: () => void;
}

const UserLocationModalBackLink = ({ onClick }: Props) => (
  <Button.Icon
    onClick={onClick}
    icon={<ArrowLeftIcon />}
    ml={12}
    mt={12}
    ariaLabel="back"
  />
);

export default UserLocationModalBackLink;
