import type { PropsWithChildren } from 'react';

import { useNeedsSignInForEligibility } from '@jane/shared/hooks';
import type { BrandSpecial } from '@jane/shared/models';
import {
  Card,
  ConditionalWrapper,
  Flex,
  JaneGoldLogo,
  PartnerLogo,
  Typography,
} from '@jane/shared/reefer';

import { JumboDiscountBadge } from '../jumboDiscountBadge';
import { LinkStyled } from './brandSpecialCard.styles';

const ConditionalLink = ({
  children,
  to,
}: PropsWithChildren<Pick<BrandSpecialCardProps, 'to'>>) => (
  <ConditionalWrapper
    condition={Boolean(to)}
    wrapper={(wrapped) => (
      <LinkStyled to={to} variant="minimal">
        {wrapped}
      </LinkStyled>
    )}
  >
    {children}
  </ConditionalWrapper>
);

export interface BrandSpecialCardProps {
  brandImage?: string | null;
  brandName: string;
  brandSpecial: BrandSpecial;
  /** `onClick` function the card should trigger */
  onClick?: () => void;
  specialImage?: string;
  specialName: string;
  tagLabel: string;
  /** Internal route to be linked to */
  to?: string;
}

export const BrandSpecialCard = ({
  brandImage,
  brandName,
  brandSpecial,
  specialImage,
  specialName,
  tagLabel,
  onClick,
  to,
}: BrandSpecialCardProps) => {
  const needsSignInForEligibility = useNeedsSignInForEligibility({
    brandSpecial,
  });

  return (
    <Card
      ariaLabel="view cash back details"
      onClick={onClick}
      width="100%"
      height="100%"
    >
      <ConditionalLink to={to}>
        {specialImage ? (
          <Card.Image
            alt={specialName}
            ariaLabel="view brand special details"
            src={specialImage}
            height="136px"
            sizes="card-lg"
            responsive
          />
        ) : (
          <Card.Content height={136}>
            <Flex
              alignItems="center"
              height="100%"
              justifyContent="center"
              width="100%"
            >
              <JumboDiscountBadge color="gold" textColor="grays-black">
                {tagLabel}
              </JumboDiscountBadge>
            </Flex>
          </Card.Content>
        )}
      </ConditionalLink>
      <ConditionalLink to={to}>
        <Card.Content>
          <Flex gap={16}>
            <Flex alignSelf="center" ml={8}>
              <PartnerLogo
                size="xs"
                name={brandName || 'brand'}
                image={brandImage}
                variant="brand"
              />
            </Flex>
            <Flex flexDirection="column" width="100%">
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <JaneGoldLogo height={24} width={60} />
                {needsSignInForEligibility && (
                  <Typography color="grays-mid" variant="mini">
                    Sign in for eligibility
                  </Typography>
                )}
              </Flex>
              <Typography color="grays-black" variant="body-bold">
                {specialName}
              </Typography>
              <Typography color="text-light" variant="body-bold">
                {brandName}
              </Typography>
            </Flex>
          </Flex>
        </Card.Content>
      </ConditionalLink>
    </Card>
  );
};
