import type { AlgoliaBrand } from '@jane/search/types';
import { useAeropayUser } from '@jane/shared-ecomm/data-access';
import { CardCarousel } from '@jane/shared/components';
import { useJaneUserId } from '@jane/shared/data-access';
import type { BrandSpecial } from '@jane/shared/models';

import { BrandSpecialCard } from '../brandSpecialCard';
import { JaneGoldCard } from '../janeGoldCard/janeGoldCard';

type BrandSpecialCardBrand = Pick<AlgoliaBrand, 'logo_url' | 'name'>;

export interface BrandSpecialCardCarouselProps {
  brandSpecialCardTo?: (args: {
    brandId: string | number;
    brandName: string;
    brandSpecialId: string | number;
  }) => string;
  brandSpecials: BrandSpecial[];
  brandsMap: {
    [brandId: number]: BrandSpecialCardBrand;
  };
  cardOnClick?: (
    brandSpecial: BrandSpecial,
    brandName: string,
    index: number
  ) => void;
  isLoading?: boolean;
  showJaneGoldCard?: boolean;
  viewAllTo?: string;
}

export const BrandSpecialCardCarousel = ({
  brandSpecialCardTo,
  brandSpecials,
  brandsMap,
  cardOnClick,
  isLoading,
  showJaneGoldCard,
  viewAllTo,
}: BrandSpecialCardCarouselProps) => {
  const id = useJaneUserId();

  const { data: aeropayUser } = useAeropayUser({
    janeUserId: id || null,
  });

  const isJaneGoldMember = !!aeropayUser?.bank_accounts.length;

  if (isLoading || brandSpecials.length > 0) {
    return (
      <CardCarousel
        cardGap={24}
        cardGapMobile={16}
        cardHeight={240}
        cardHeightMobile={240}
        cardWidth={420}
        cardWidthMobile={327}
        isLoading={isLoading}
        mode="ideal-width"
        my={24}
        name="Jane Gold rewards"
        buttonTo={viewAllTo}
      >
        {showJaneGoldCard && (
          <CardCarousel.Card key="jane-gold-card">
            <JaneGoldCard isJaneGoldMember={!!isJaneGoldMember} />
          </CardCarousel.Card>
        )}
        {brandSpecials.map((brandSpecial, index) => {
          const brand = brandsMap[brandSpecial.product_brand_id];

          return (
            <CardCarousel.Card key={brandSpecial.id}>
              <BrandSpecialCard
                brandImage={brand?.logo_url}
                brandName={brand?.name}
                brandSpecial={brandSpecial}
                onClick={() => {
                  cardOnClick && cardOnClick(brandSpecial, brand?.name, index);
                }}
                specialImage={brandSpecial?.photo?.urls.original}
                specialName={brandSpecial?.title}
                tagLabel={brandSpecial?.discount_label}
                to={
                  brandSpecialCardTo &&
                  brandSpecialCardTo({
                    brandId: brandSpecial?.product_brand_id,
                    brandName: brand?.name,
                    brandSpecialId: brandSpecial?.id,
                  })
                }
              />
            </CardCarousel.Card>
          );
        })}
      </CardCarousel>
    );
  }

  return null;
};
