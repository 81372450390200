import type { ReactNode } from 'react';

import type { KindOrType } from '@jane/search/types';
import { Typography } from '@jane/shared/reefer';

import { titleize } from '../lib/titleCase';

interface Props {
  children?: ReactNode;
  dark?: boolean;
  small?: boolean;
  titleCase?: boolean;
  type: KindOrType;
  typeLabel?: string;
}

const Badge: React.FC<Omit<Props, 'type'>> = ({ children, dark, small }) => {
  return (
    <Typography
      color={dark ? 'grays-white' : 'grays-black'}
      as="div"
      background={dark ? 'grays-black' : 'grays-white'}
      border="grays-black"
      css={{
        borderRadius: 3,
      }}
      py={2}
      px={small ? 4 : 8}
      textAlign="center"
      variant="body"
    >
      {children}
    </Typography>
  );
};

const TypeBadge = ({
  dark = false,
  small = false,
  type,
  typeLabel,
  titleCase = true,
}: Props) => {
  const label = typeLabel || type;

  return (
    <Badge dark={dark} small={small}>
      {titleCase ? titleize(label) : label}
    </Badge>
  );
};

export default TypeBadge;
