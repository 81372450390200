import type { Location } from 'history';
import type { ReactNode } from 'react';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useQuery } from '@jane/shared-ecomm/hooks';
import { getJwt } from '@jane/shared/auth';
import type { AppMode } from '@jane/shared/models';
import { useEcommApp } from '@jane/shared/providers';

import { useCustomerSelector } from '../customer/selectors';
import { normalizeRedirectPath } from '../lib/normalizeRedirectPath';
import { paths } from '../lib/routes';
import { get } from '../redux-util/selectors';

interface LocationState {
  nextPathname?: string;
}

const resolveRedirectPath = (
  { state }: Location<LocationState>,
  query: { redirect_after_login?: string },
  appMode: AppMode
): string => {
  if (query?.redirect_after_login) {
    return normalizeRedirectPath(query.redirect_after_login);
  }
  if (state && state.nextPathname) {
    return state.nextPathname;
  }

  if (appMode === 'default') {
    return paths.root();
  }
  if (appMode === 'brandEmbed') {
    return paths.profile();
  } else {
    return paths.embeddedMenu();
  }
};

const EnsureNotLoggedInContainer = ({ children }: { children?: ReactNode }) => {
  const location = useLocation();
  const query = useQuery();
  const navigate = useNavigate();
  const { authenticated: isLoggedIn } = useCustomerSelector(get('customer'));
  const { appMode } = useEcommApp();
  const redirect = useCallback(() => {
    window.scrollTo(0, 0);
    navigate(resolveRedirectPath(location, query, appMode), {
      replace: true,
    });
  }, [appMode, location]);
  const jwt = getJwt();

  useEffect(() => {
    if (isLoggedIn && jwt) {
      redirect();
    }
  }, [isLoggedIn, appMode]);

  return <>{children}</>;
};

export default EnsureNotLoggedInContainer;
