import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import {
  BankLinkingModalWithPrompt,
  JaneGoldBanner,
} from '@jane/shared-ecomm/components';
import { useShowUserPreferencesButton } from '@jane/shared-ecomm/hooks';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { NavBarTapSection, trackNavBarTap } from '@jane/shared-ecomm/tracking';
import type { Store } from '@jane/shared/models';
import {
  Box,
  Flex,
  Grid,
  JaneLogo,
  Link,
  Modal,
  useDesktopMediaQuery,
  useMobileMediaQuery,
  useTabletMediaQuery,
} from '@jane/shared/reefer';

import { useCustomerDispatch } from '../../customer/dispatch';
import { clearSearchQuery } from '../../customer/redux/search';
import { useCustomerSelector } from '../../customer/selectors';
import { paths } from '../../lib/routes';
import { get } from '../../redux-util/selectors';
import TypeAheadSearch from '../search/typeAheadSearch';
import HeaderCartContainer from './cartContainer';
import { StyledHeader } from './header.styles';
import NavLinksModal from './navLinksModal';
import HeaderNavigation from './navigation';
import UserActions from './userActions';
import { UserPreferencesButton } from './userPreferencesButton';

const SCROLL_EVENT_THROTTLE = 250;

interface HeaderProps {
  /** Whether to hide the search bar.
   *  Search bar is always displayed when:
   *  - the header has scrolled over the home page search bar
   *  - on mobile on the `static` variant of the header
   * */
  hideSearch: boolean;

  /** The header has two variants:
   *  Static:
   *  - always displaus with an opaque white background
   *  - always shows the search bar on mobile
   *  Dynamic:
   *  - displays with a transparent background, until the user scrolls, when it reverts to an opaque white background
   *  - does not display the search bar on mobile until the user has scrolled
   */
  variant?: 'static' | 'dynamic';
}

const Header = ({ hideSearch, variant = 'static' }: HeaderProps) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [hasScrolledOverSearch, setHasScrolledOverSearch] = useState(false);

  const [showOverlay, setShowOverlay] = useState(false);
  const location = useLocation();
  const showJaneGoldBanner = !matchRoutes(
    [{ path: '/dispensaries/*' }],
    location
  );

  const dispatch = useCustomerDispatch();
  const isDesktop = useDesktopMediaQuery({});
  const isMobile = useMobileMediaQuery({ size: 'legacy' });
  const isLargerThanMobile = useTabletMediaQuery({});
  const {
    userLocation: { cityState, zipcode },
    userPreferences: { storeFulfillmentType, storeSearchRadius, storeType },
  } = useUserPreferences();

  const showUserPreferencesButton = useShowUserPreferencesButton();

  const { id: customerId } = useCustomerSelector(({ customer: { id } }) => ({
    id,
  }));

  const { store } = useCustomerSelector(get('store'));

  useEffect(() => {
    const onHostScroll = () => {
      const heroForm = document.getElementById('hero-search');
      const header = document.querySelector('header');

      setHasScrolled(window.scrollY > 10);
      setHasScrolledOverSearch(
        header &&
          heroForm &&
          heroForm.getBoundingClientRect().top - header.offsetHeight * 0.4 <=
            window.scrollY
      );
    };

    const throttledOnHostScroll = () =>
      throttle(onHostScroll, SCROLL_EVENT_THROTTLE)();

    window.addEventListener('scroll', throttledOnHostScroll);

    return () => window.removeEventListener('scroll', throttledOnHostScroll);
  }, [isMobile]);

  const clearSearch = () => {
    dispatch(clearSearchQuery());
  };

  const toggleOverlay = () => {
    setShowOverlay((prevValue) => !prevValue);
  };

  const trackNavBarTapEvent = (navBarTap: NavBarTapSection) =>
    trackNavBarTap({
      cityState,
      navBarTap,
      source: location.pathname,
      storeFulfillmentType,
      storeSearchRadius,
      storeType,
      zipcode,
    });

  const backgroundColor =
    variant === 'static' || hasScrolled ? 'white' : 'transparent';
  const color = backgroundColor === 'transparent' ? 'grays-white' : undefined;
  const showLogo = variant === 'static' || hasScrolled;
  const showBigLogo = isDesktop && showLogo;

  return (
    <>
      <StyledHeader
        backgroundColor={backgroundColor}
        data-testid="main-header"
        id="main-header"
      >
        <Grid.Container spacing={0}>
          <Grid.Item xs={4} sm={3} lg={showBigLogo ? 5 : 6}>
            <HeaderNavigation
              color={color}
              showLogo={showLogo}
              toggleOverlay={toggleOverlay}
              onClearSearch={clearSearch}
              onTrackTap={trackNavBarTapEvent}
            />
          </Grid.Item>

          {showBigLogo && (
            <Grid.Item xs={4} sm={2}>
              <Flex alignItems="center" justifyContent="center" height="100%">
                <Link
                  onClick={() => {
                    clearSearch();
                    trackNavBarTapEvent(NavBarTapSection.Logo);
                  }}
                  to={paths.root()}
                  aria-label="Jane"
                  variant="minimal"
                  css={{ pointerEvents: 'all', zIndex: 710 }}
                >
                  <JaneLogo variant="secondary" size="sm" color="black" />
                </Link>
              </Flex>
            </Grid.Item>
          )}

          <Grid.Item xs={8} sm={9} lg={showBigLogo ? 5 : 6}>
            <Flex width="100%" justifyContent="flex-end" ml="auto">
              {(!hideSearch ||
                hasScrolledOverSearch ||
                (isMobile && variant === 'static')) && (
                <Box>
                  <TypeAheadSearch
                    searchFormMode="header"
                    placeHolder='Try "Sleep"'
                    formId="global-search"
                  />
                </Box>
              )}

              <UserActions
                color={color}
                hasUser={!!customerId}
                onTrackTap={trackNavBarTapEvent}
              />
              <HeaderCartContainer
                color={color}
                onTrackTap={trackNavBarTapEvent}
              />
            </Flex>
          </Grid.Item>

          {showUserPreferencesButton && !hideSearch && !isLargerThanMobile && (
            <Grid.Item xs={12}>
              <Flex justifyContent="center">
                <UserPreferencesButton />
              </Flex>
            </Grid.Item>
          )}
        </Grid.Container>

        <Modal
          background="grays-white"
          variant="full-screen"
          contentLabel="title"
          open={showOverlay}
          onRequestClose={() => setShowOverlay(false)}
        >
          <Modal.Content>
            <NavLinksModal
              onClose={() => setShowOverlay(false)}
              onTrackTap={trackNavBarTapEvent}
            />
          </Modal.Content>
        </Modal>
      </StyledHeader>
      <BankLinkingModalWithPrompt
        customerId={customerId}
        store={store as Store}
      />
      {showJaneGoldBanner && (
        <JaneGoldBanner transparent={backgroundColor !== 'white'} />
      )}
    </>
  );
};

export default Header;
