import styled from '@emotion/styled';
import type { FormEvent, MouseEvent } from 'react';

import { connectSearchBox } from '@jane/search/data-access';
import { useShowUserPreferencesButton } from '@jane/shared-ecomm/hooks';
import {
  Button,
  DismissFilledIcon,
  Flex,
  SearchFilledIcon,
  SearchIcon,
  Typography,
  useMobileMediaQuery,
  useTabletMediaQuery,
} from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

import { UserPreferencesButton } from '../header/userPreferencesButton';
import OffscreenLabel from '../offscreenLabel';
import type { SearchFormMode } from './form';
import { SEARCH_RESULTS_Z_INDEX } from './helper';

export const SEARCH_FORM_HEIGHT = 48;
export const SEARCH_FORM_HEIGHT_LARGE = 50;
export const SEARCH_FORM_HEIGHT_XLARGE = 72;

const Form = styled.form<{
  expandSearchBar: boolean;
  focused: boolean;
  mode: SearchFormMode;
}>(
  {
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    transition: 'all 500ms',
  },
  ({ theme }) => ({
    backgroundColor: theme.colors.grays.white,
    borderRadius: theme.borderRadius.lg,
  }),
  ({ mode, expandSearchBar, focused, theme }) => [
    {
      border: focused
        ? `2px solid ${theme.colors.brand.purple.main}`
        : `2px solid ${
            expandSearchBar ? theme.colors.grays.light : 'transparent'
          }`,
    },
    mode === 'header' && {
      height: SEARCH_FORM_HEIGHT,
      [mediaQueries.mobile('legacy', 'max')]: [{ transitionDuration: '0ms' }],
    },
    mode === 'header' && (expandSearchBar || focused) && spacing({ mx: 12 }),
    mode === 'header' &&
      !expandSearchBar && {
        width: '12%',
        minWidth: '48px',
        [mediaQueries.mobile('legacy', 'max')]: [
          spacing({ mx: 'auto' }),
          { width: '100%' },
        ],
        [mediaQueries.tablet('max')]: [{ maxWidth: 'none' }],
      },
    !focused &&
      expandSearchBar &&
      mode === 'header' && {
        [mediaQueries.mobile('legacy', 'min')]: {
          width: '200px',
        },
      },
    focused &&
      mode !== 'hero' && {
        [mediaQueries.mobile('legacy', 'max')]: {
          borderRadius: theme.borderRadius.lg,
          height: SEARCH_FORM_HEIGHT,
          left: 0,
          position: 'absolute',
          top: 0,
          width: 'calc(100vw - 48px)',
          zIndex: SEARCH_RESULTS_Z_INDEX + 1,
          ...spacing({ mx: 24, my: 16 }),
        },
      },
    mode === 'body' && [
      {
        border: `1px solid ${theme.colors.grays.mid}`,
        [mediaQueries.tablet('min')]: {
          height: SEARCH_FORM_HEIGHT_LARGE,
        },
      },
    ],
    mode === 'hero' && [
      {
        border: `2px solid ${theme.colors.brand.purple.main}`,
        height: SEARCH_FORM_HEIGHT_LARGE,
        [mediaQueries.tablet('min')]: {
          borderRadius: theme.borderRadius.xl,
          height: SEARCH_FORM_HEIGHT_XLARGE,
          paddingLeft: '10px',
          paddingRight: '19px',
        },
      },
    ],
  ]
);

const FormWrapper = styled(Flex)<{
  expandSearchBar: boolean;
  mode: string;
}>(({ expandSearchBar, mode, theme }) => [
  expandSearchBar &&
    mode !== 'hero' && {
      [mediaQueries.mobile('legacy', 'max')]: {
        backgroundColor: theme.colors.grays.white,
        height: '80px',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100vw',
        zIndex: SEARCH_RESULTS_Z_INDEX + 1,
      },
    },
]);

const Input = styled.input<{ mode: SearchFormMode }>(({ mode, theme }) => ({
  ...spacing({ pl: mode === 'header' ? 0 : 16, pr: 8, py: 0 }),
  border: 'none',
  fontFamily: 'Jane Default',
  borderRadius: 0,
  backgroundColor: theme.colors.grays.white,
  flex: '1 1 auto',
  height: 25,
  lineHeight: '25px', // vertically aligns placeholder text
  outline: 'none',
  width: '100%',
  WebkitAppearance: 'none',
  webkitFontFeatureSettings: "'ss01' 1, 'ss02' 1, 'ss03' 1",
  mozFontFeatureSettings: "'ss01' 1, 'ss02' 1, 'ss03' 1",
  fontFeatureSettings: "'ss01' 1, 'ss02' 1, 'ss03' 1",
  '&::-ms-clear': {
    display: 'none',
  },
  '&::-webkit-search-cancel-button': {
    display: 'none',
  },
  '&:-webkit-autofill': {
    webkitBoxShadow: '0 0 0 100px white inset',
  },
}));

const ClearButton = styled.button(
  { alignItems: 'center', display: 'flex', justifyContent: 'center' },
  spacing({ pr: 8 })
);

interface SubmitButtonProps {
  mode: SearchFormMode;
  setSearchFocused?: (boolean) => void;
  shouldSubmit?: boolean;
}

const ButtonIcon = ({ mode }: { mode: SearchFormMode }) => {
  if (mode === 'header') return <SearchFilledIcon />;
  return <SearchIcon color="purple" />;
};

const StyledButtonIcon = styled(Button.Icon)({
  '&:hover, &:active': {
    '&:before': {
      backgroundColor: 'transparent',
    },
  },
});

const SubmitButton = ({
  mode,
  shouldSubmit = true,
  setSearchFocused,
}: SubmitButtonProps) => {
  const handleSetFocus = (e: MouseEvent<Element>) => {
    e.preventDefault();
    setSearchFocused(true);
  };

  return (
    <StyledButtonIcon
      ml={-2}
      variant="minimal"
      icon={<ButtonIcon mode={mode} />}
      type={shouldSubmit ? 'submit' : 'button'}
      aria-label="search"
      data-testid="typeahead-search-button"
      {...(!shouldSubmit &&
        setSearchFocused && {
          onClick: (e) => handleSetFocus(e),
        })}
    />
  );
};

const CancelSearchButton = styled.button(spacing({ px: 4 }), {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
});

interface Props extends TypeAheadSearchInputProps {
  currentRefinement: string;
  refine: (arg: string) => void;
}

const shouldShowClearButton = (value: string) => value && value.length;

const ConnectingSearchInput = ({
  searchType,
  refine,
  currentRefinement,
  mode = 'body',
  onSubmit,
  formId,
  placeholder,
  searchFocused,
  setSearchFocused,
}: Props) => {
  const isMobile = useMobileMediaQuery({ size: 'legacy' });
  const isLargerThanMobile = useTabletMediaQuery({});
  const showPreferencesButton = useShowUserPreferencesButton();

  return (
    <FormWrapper
      expandSearchBar={searchFocused}
      mode={mode}
      width="100%"
      alignItems="center"
      justifyContent="flex-end"
    >
      {showPreferencesButton && mode === 'header' && isLargerThanMobile && (
        <UserPreferencesButton />
      )}
      <Form
        action="#"
        expandSearchBar={
          searchFocused || !!currentRefinement || !isLargerThanMobile
        }
        focused={searchFocused}
        onSubmit={(event) => onSubmit(event, currentRefinement)}
        mode={mode}
        id={formId}
      >
        <Flex alignItems="center" justifyContent="space-between" height="100%">
          <OffscreenLabel htmlFor={searchType}>Search</OffscreenLabel>
          <SubmitButton
            mode={mode}
            {...(mode === 'header' && {
              setSearchFocused: setSearchFocused,
              shouldSubmit: searchFocused,
            })}
          />
          <Input
            id={searchType}
            name={searchType}
            mode={mode}
            onChange={(e) => refine(e.target.value)}
            type="search"
            value={currentRefinement}
            autoComplete="off"
            placeholder={
              !isLargerThanMobile || searchFocused || mode !== 'header'
                ? placeholder
                : ''
            }
            onFocus={() => setSearchFocused(true)}
            ref={(input) =>
              input && (searchFocused ? input.focus() : input.blur())
            }
          />
          {(shouldShowClearButton(currentRefinement) || searchFocused) && (
            <ClearButton
              onClick={() => {
                refine('');
                setSearchFocused(false);
              }}
              type="reset"
              aria-label="reset"
            >
              <DismissFilledIcon size="md" />
            </ClearButton>
          )}

          {isMobile && searchFocused && mode !== 'header' && mode !== 'hero' && (
            <CancelSearchButton
              onClick={() => {
                refine('');
                setSearchFocused(false);
              }}
            >
              <Typography color="grays-black" variant="body">
                Cancel
              </Typography>
            </CancelSearchButton>
          )}
        </Flex>
      </Form>
    </FormWrapper>
  );
};

const ConnectedSearchInput = connectSearchBox(ConnectingSearchInput);

interface TypeAheadSearchInputProps {
  formId: string;
  mode: SearchFormMode;
  onSubmit: (event: FormEvent<HTMLFormElement>, searchQuery: string) => void;
  placeholder: string;
  searchFocused: boolean;
  searchType: string;
  setSearchFocused: (arg: boolean) => void;
}

const TypeAheadSearchInput = ({
  searchType,
  mode,
  onSubmit,
  formId,
  placeholder,
  searchFocused,
  setSearchFocused,
}: TypeAheadSearchInputProps) => (
  <ConnectedSearchInput
    onSubmit={onSubmit}
    mode={mode}
    searchType={searchType}
    formId={formId}
    placeholder={placeholder}
    searchFocused={searchFocused}
    setSearchFocused={setSearchFocused}
  />
);

export default TypeAheadSearchInput;
