import { useState } from 'react';

import { useAuthentication } from '@jane/shared-ecomm/providers';
import { useJaneUser } from '@jane/shared/data-access';
import { Link } from '@jane/shared/reefer';

import { LogInModal } from './logInModal';

export const JaneGoldSignInForEligibilityLink = ({
  includeParens,
}: {
  includeParens?: boolean;
}) => {
  const { data: janeUser } = useJaneUser();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { defaultLogin, register, resetError, resetPassword, ...authProps } =
    useAuthentication();

  if (janeUser?.user.authenticated) {
    return null;
  }

  return (
    <>
      <Link
        onClick={(e) => {
          e.stopPropagation();
          setShowLoginModal(true);
        }}
      >
        {includeParens && '('}Sign in for eligibility{includeParens && ')'}
      </Link>
      {showLoginModal && (
        <LogInModal
          {...authProps}
          location="header"
          onSignInSubmit={defaultLogin}
          onCreateAccountSubmit={register}
          onForgotPasswordSubmit={resetPassword}
          open
          onRequestClose={() => setShowLoginModal(false)}
        />
      )}
    </>
  );
};
