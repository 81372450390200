import { CountDot } from '@jane/shared/components';
import { AccountIcon, Box } from '@jane/shared/reefer';
import type { ColorKey } from '@jane/shared/reefer';

interface Props {
  color?: ColorKey;
  inProgressReservations?: number;
  pendingCashBack?: number | string;
}

export default function ProfileButton({
  color,
  inProgressReservations,
  pendingCashBack,
}: Props) {
  return (
    <Box position="relative" m={12}>
      <AccountIcon color={color} />
      {!!inProgressReservations && (
        <CountDot
          ariaLabel="active reservations"
          count={inProgressReservations}
        />
      )}
      {!inProgressReservations && !!pendingCashBack && (
        <CountDot ariaLabel="pending cash back" gold count={'$'} />
      )}
    </Box>
  );
}
