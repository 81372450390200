import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { shallowEqual } from 'react-redux';

import { LoadingWrapper } from '@jane/shared/components';
import { useJaneUser } from '@jane/shared/data-access';
import { getFlagContext, useFlagClient } from '@jane/shared/feature-flags';
import type { Store } from '@jane/shared/models';
import { statePostalCode } from '@jane/shared/util';

import { useCustomerDispatch } from '../../customer/dispatch';
import { getCart } from '../../customer/redux/cart';
import { whoamiSuccess } from '../../customer/redux/customer';
import { useCustomerSelector } from '../../customer/selectors';
import { useRecordPageView } from '../../hooks/useRecordPageView';
import CheckAuthTimer from '../checkAuthTimer';

interface Props {
  children: ReactNode;
}

export const CustomerAppContainer = ({ children }: Props) => {
  useRecordPageView();

  const [identifyingFlagUser, setIdentifyingFlagUser] = useState(true);

  const flagClient = useFlagClient();
  const dispatch = useCustomerDispatch();
  const { data: janeUser, isLoading: isLoadingUser } = useJaneUser();
  const authenticated = janeUser?.user?.id;

  // TODO: Remove this once we fully remove the customer redux state
  useEffect(() => {
    if (janeUser?.user) {
      dispatch(whoamiSuccess(janeUser?.user));
    }
  }, [janeUser?.user]);

  const { janeDeviceId, storeId, state } = useCustomerSelector(
    ({ application: { janeDeviceId }, store: { store } }) => ({
      janeDeviceId,
      storeId: (store as Store).id,
      state: (store as Store).state,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getCart());
  }, []);

  useEffect(() => {
    const identifyFlagClient = async () => {
      await flagClient.waitUntilReady();
      if (identifyingFlagUser) {
        if (!isLoadingUser && authenticated && janeDeviceId) {
          try {
            await flagClient.identify(
              getFlagContext({
                extraAttributes: {
                  appMode: 'default',
                  storeId: storeId,
                  state: statePostalCode(state),
                },
                janeDeviceId,
                user: janeUser.user,
              })
            );
            setIdentifyingFlagUser(false);
          } catch (e) {
            console.error('Identifying user on flagClient failed: ', e);
            // Don't prevent the page from loading if identifying the user in LD fails
            setIdentifyingFlagUser(false);
          }
        } else {
          setIdentifyingFlagUser(false);
        }
      }
    };

    identifyFlagClient();
  }, [authenticated, isLoadingUser]);

  const isLoading = isLoadingUser || identifyingFlagUser;

  return (
    <LoadingWrapper isLoading={isLoading} variant="full-page">
      <CheckAuthTimer />
      {children}
    </LoadingWrapper>
  );
};
