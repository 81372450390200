import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import pluralise from 'pluralise';
import { Link } from 'react-router-dom';

import { Box, Flex, Typography } from '@jane/shared/reefer';

import { SvgIcon } from '../../component-library/svg-icon';
import ReviewsCount, { reviewsCountWrapperClass } from './reviewsCount';

interface Props {
  href?: string;
  onClick?: () => void;
  rating: number;
  reviewsCount?: number;
}

const ReviewLink = styled(Link)({
  display: 'flex',
  '@media (hover: hover)': {
    [`.${reviewsCountWrapperClass}:hover`]: {
      textDecoration: 'underline',
    },
  },
});

const StarOffset = styled(Flex)({
  transform: 'translateY(-1.25px)',
});

const generateAriaLabel = (rating: number, reviewsCount?: number) =>
  reviewsCount
    ? `rated ${rating.toFixed(
        1
      )} out of 5 stars with ${reviewsCount} ${pluralise(
        reviewsCount,
        'review'
      )}`
    : 'No rating';

const AverageRating = ({ rating, reviewsCount, href, onClick }: Props) => {
  const theme = useTheme();
  const Wrapper = href ? ReviewLink : Flex;
  const wrapperProps = href
    ? {}
    : {
        ariaLabel: generateAriaLabel(rating, reviewsCount),
      };

  return (
    <Wrapper
      css={{ alignItems: 'center' }}
      to={href || ''}
      onClick={onClick}
      {...wrapperProps}
    >
      <StarOffset mr={4}>
        <SvgIcon
          icon={rating > 0 ? 'starFilled' : 'starEmpty'}
          color={theme.colors.brand.gold.main}
        />
      </StarOffset>
      {rating > 0 && (
        <Box aria-hidden="true">
          <Typography variant="body">{rating.toFixed(1)}</Typography>
        </Box>
      )}
      {rating > 0 && reviewsCount != null && <Box ml={4} />}
      {reviewsCount != null && <ReviewsCount count={reviewsCount} />}
    </Wrapper>
  );
};

export default AverageRating;
