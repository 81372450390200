import { useLocation, useNavigate } from 'react-router-dom';

import { useSafeState, useShouldShowGold } from '@jane/shared/hooks';
import {
  Box,
  Button,
  Card,
  DismissIcon,
  Flex,
  JaneGoldLogo,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { Storage } from '@jane/shared/util';

import { BannerContainer, StyledJaneGoldBanner } from './janeGoldBanner.styles';

interface JaneGoldBannerProps {
  transparent: boolean;
}

export const DISMISS_JANE_GOLD_BANNER_KEY = 'dismissJaneGoldBanner';

const shouldDisplayBanner = () => {
  const expireTime = Storage.get(DISMISS_JANE_GOLD_BANNER_KEY);
  const dismissHasExpired = expireTime
    ? expireTime <= new Date().getTime()
    : true;

  if (dismissHasExpired) {
    Storage.remove(DISMISS_JANE_GOLD_BANNER_KEY);
  }

  return (
    !dismissHasExpired ||
    /bot|google|baidu|bing|msn|teoma|slurp|yandex/i.test(navigator.userAgent) // check for search crawler
  );
};

// 30 days
const DISMISS_MILLISECONDS = 30 * 24 * 60 * 60 * 1000;

export function JaneGoldBanner({ transparent }: JaneGoldBannerProps) {
  const isMobile = useMobileMediaQuery({});
  const shouldShowGold = useShouldShowGold();
  const [bannerDismissed, setBannerDismissed] = useSafeState(
    shouldDisplayBanner()
  );
  const location = useLocation();
  const navigate = useNavigate();
  const isFullWidth = location.pathname !== '/' || isMobile || !transparent;
  const bannerLinkPath =
    '/brands?utm_source=website&utm_medium=Jane&utm_content=banner';
  const bannerText = 'Get Jane Gold rewards from your favorite brands';

  const onDismiss = () => {
    const now = new Date();
    const expire = now.getTime() + DISMISS_MILLISECONDS;

    Storage.set(DISMISS_JANE_GOLD_BANNER_KEY, expire);
    setBannerDismissed(true);
  };

  return !bannerDismissed && shouldShowGold ? (
    <BannerContainer>
      <StyledJaneGoldBanner
        fullWidth={isFullWidth}
        direction="row"
        width={isFullWidth ? '100%' : 'calc(100% - 96px)'}
      >
        <Card.Content
          onClick={onDismiss}
          ariaLabel="Dismiss Jane Gold banner"
          width="24px"
          height="auto"
        >
          <Flex alignItems="center" height="100%">
            <Box>
              <DismissIcon size="sm" color="grays-light" />
            </Box>
          </Flex>
        </Card.Content>
        <Card.Content
          onClick={() => navigate(bannerLinkPath)}
          ariaLabel={bannerText}
        >
          <Flex width="100%" justifyContent="space-between">
            <Box>
              <JaneGoldLogo width={60} height="auto" />
              <Link variant={!isMobile ? 'minimal' : 'underline'}>
                <Typography>{bannerText}</Typography>
              </Link>
            </Box>
            {!isMobile && <Button variant="primary" label="Shop now"></Button>}
          </Flex>
        </Card.Content>
      </StyledJaneGoldBanner>
    </BannerContainer>
  ) : null;
}
