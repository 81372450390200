import styled from '@emotion/styled';
import pluralise from 'pluralise';

import { Typography } from '@jane/shared/reefer';

const formatReviewsCount = (reviewsCount: number) => {
  if (reviewsCount >= 10000) {
    return `${(reviewsCount / 1000).toFixed(1)}K`;
  }
  return reviewsCount;
};

interface Props {
  count: number;
}

export const reviewsCountWrapperClass = 'reviews-count-wrapper';

const ReviewsCountWrapper = styled.span({
  whiteSpace: 'nowrap',
});

const ReviewsCount = ({ count }: Props) => (
  <ReviewsCountWrapper className={reviewsCountWrapperClass}>
    <Typography color="grays-mid" variant="body">
      ({formatReviewsCount(count)} {pluralise(count, 'review')})
    </Typography>
  </ReviewsCountWrapper>
);

export default ReviewsCount;
