import omit from 'lodash/omit';

import type {
  AlgoliaDispensaryFacets,
  FilterGroup,
  SelectFilterGroup,
} from '@jane/search/types';
import {
  OWNERSHIP_ICONS,
  SERVICES_ICONS,
  customDealLabels,
  customOwnershipLabels,
  customServicesLabel,
  facetToFilter,
} from '@jane/search/util';
import type { Nullable } from '@jane/shared/types';

interface FormattedDispensaryFilterProps {
  facets: AlgoliaDispensaryFacets;
}

export const useFormattedDispensaryFilters = ({
  facets,
}: FormattedDispensaryFilterProps): FilterGroup[] => {
  const dealFiltersToOmit = ['Industry discounts'];
  const serviceFiltersToOmit = ['cash', 'debit'];

  const dealsFilters: Nullable<SelectFilterGroup> = facets.current_deals
    ? {
        items: facetToFilter(omit(facets.current_deals, dealFiltersToOmit), {
          customLabels: customDealLabels,
        }),
        key: 'current_deals',
        label: 'Deals',
        showInFilterBar: true,
        modalItemsPerRow: 3,
        type: 'multiselect',
      }
    : null;

  const ownershipFilters: Nullable<SelectFilterGroup> =
    facets.ownership_identification
      ? {
          items: facetToFilter(facets.ownership_identification, {
            customLabels: customOwnershipLabels,
            icons: OWNERSHIP_ICONS,
          }),
          key: 'ownership_identification',
          label: 'Ownership',
          showInFilterBar: true,
          type: 'multiselect',
        }
      : null;

  const servicesFilters: Nullable<SelectFilterGroup> = facets.store_options
    ? {
        items: facetToFilter(omit(facets.store_options, serviceFiltersToOmit), {
          customLabels: customServicesLabel,
          icons: SERVICES_ICONS,
        }),
        key: 'store_options',
        label: 'Services',
        showInFilterBar: true,
        modalItemsPerRow: 2,
        type: 'multiselect',
      }
    : null;

  return [
    dealsFilters,
    ownershipFilters,
    servicesFilters,
  ].filter<SelectFilterGroup>(
    (filterGroup): filterGroup is SelectFilterGroup => filterGroup !== null
  );
};
