export const priceIdToWeight = {
  each: 'ea',
  half_gram: '.5g',
  gram: '1g',
  two_gram: '2g',
  eighth_ounce: '3.5g',
  quarter_ounce: '7g',
  half_ounce: '14g',
  ounce: '28g',
};

export const getPrices = (
  price: number | string,
  discountedPrice?: string | number
) => {
  const parsedPrice = typeof price === 'string' ? parseFloat(price) : price;
  const parsedDiscountedPrice =
    typeof discountedPrice === 'string'
      ? parseFloat(discountedPrice)
      : discountedPrice;

  const priceAsCurrency = parsedPrice.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const discountedPriceAsCurrency =
    parsedDiscountedPrice &&
    parsedDiscountedPrice.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });

  return {
    price: priceAsCurrency,
    discountedPrice: discountedPriceAsCurrency,
  };
};
