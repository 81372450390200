import type { ReactNode } from 'react';

import { userLocationToParams } from '@jane/search/util';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import {
  Box,
  ConditionalWrapper,
  Flex,
  JaneLogo,
  Link,
  Typography,
  useTabletMediaQuery,
} from '@jane/shared/reefer';

import { paths } from '../../lib/routes';
import {
  FooterColumnStyled,
  FooterContainer,
  FooterLinkStyled,
  FooterSectionLegal,
  FooterSectionLinks,
  FooterSectionLogo,
  FooterSectionSoc2,
} from './footer.styles';
import { Soc2Image } from './soc2Image';

interface FooterColumnProps {
  children: ReactNode;
}

const FooterColumn = ({ children }: FooterColumnProps) => (
  <FooterColumnStyled pr={32} mb={32}>
    {children}
  </FooterColumnStyled>
);

interface FooterHeaderProps {
  children: ReactNode;
}

const FooterHeader = ({ children }: FooterHeaderProps) => (
  <Typography branded color="grays-white" mb={16} variant="body-bold" as="p">
    {children}
  </Typography>
);

interface FooterLinkProps {
  children: ReactNode;
  hasMarginRight?: boolean;
  href?: string;
  minWidth?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  to?: string;
}

const FooterLink = ({
  children,
  hasMarginRight = false,
  href,
  target,
  to,
}: FooterLinkProps) => (
  <FooterLinkStyled
    color="grays-white"
    branded
    hasMarginRight={hasMarginRight}
    mb={16}
    variant="body"
  >
    <ConditionalWrapper
      condition={!!(to || href)}
      wrapper={(children) => (
        <Link
          color="grays-white"
          target={target}
          to={to}
          href={href}
          variant="minimal"
        >
          {children}
        </Link>
      )}
    >
      {children}
    </ConditionalWrapper>
  </FooterLinkStyled>
);

export function Footer() {
  const isTablet = useTabletMediaQuery({ width: 'min' });
  const {
    userLocation: { cityState, countryCode },
  } = useUserPreferences();
  const userLocationPath = userLocationToParams(cityState, countryCode);

  return (
    <Box as="footer" background="primary" className="footer">
      <FooterContainer
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        ml="auto"
        mr="auto"
        maxWidth="1440px"
        position="relative"
        width="100%"
      >
        <FooterSectionLinks flexWrap="wrap">
          <FooterColumn>
            <FooterHeader>Shop</FooterHeader>
            <FooterLink to={paths.products()}>Products</FooterLink>
            <FooterLink to={paths.brandsLanding()}>Brands</FooterLink>
            <FooterLink to={paths.brandJaneGold()}>Jane Gold</FooterLink>
            <FooterLink
              to={
                userLocationPath
                  ? paths.dispensaries({
                      country: userLocationPath.country,
                      state: userLocationPath.state,
                      city: userLocationPath.city,
                    })
                  : paths.dispensaries({})
              }
            >
              Dispensaries
            </FooterLink>
            <FooterLink to={paths.directory()}>Directory</FooterLink>
            <FooterLink href="https://www.iheartjane.com/community/strains">
              Strains
            </FooterLink>
          </FooterColumn>

          <FooterColumn>
            <FooterHeader>For businesses</FooterHeader>
            <FooterLink href="https://iheartjane.com/overview">
              Overview
            </FooterLink>
            <FooterLink href="https://iheartjane.com/dispensaries-overview">
              Dispensaries
            </FooterLink>
            <FooterLink href="https://iheartjane.com/brands-overview">
              Brands
            </FooterLink>
          </FooterColumn>

          <FooterColumn>
            <FooterHeader>Solutions</FooterHeader>
            <FooterLink href="https://iheartjane.com/ecommerce">
              Ecommerce
            </FooterLink>
            <FooterLink href="https://iheartjane.com/pos">
              Point of Sale
            </FooterLink>
            <FooterLink href="https://iheartjane.com/payments">
              Payments
            </FooterLink>
            <FooterLink href="https://iheartjane.com/kiosk">Kiosk</FooterLink>
            <FooterLink href="https://iheartjane.com/media">Media</FooterLink>
            <FooterLink href="https://iheartjane.com/analytics ">
              Analytics
            </FooterLink>
            <FooterLink href="https://iheartjane.com/catalog">
              Catalog
            </FooterLink>
            <FooterLink href="https://iheartjane.com/integrations">
              Integrations
            </FooterLink>
            <FooterLink href="https://www.iheartjane.com/myhigh">
              MyHigh
            </FooterLink>
            <FooterLink href="https://www.iheartjane.com/janegold-overview">
              Jane Gold
            </FooterLink>
          </FooterColumn>

          <FooterColumn>
            <FooterHeader>Contact</FooterHeader>
            <FooterLink href="mailto:info@iheartjane.com">Investors</FooterLink>
            <FooterLink href="mailto:jane@mattio.com">Press & Media</FooterLink>
          </FooterColumn>

          <FooterColumn>
            <FooterHeader>Learn</FooterHeader>
            <FooterLink href="https://www.iheartjane.com/team">Team</FooterLink>
            <FooterLink href="https://www.iheartjane.com/team#careers">
              Careers
            </FooterLink>
            <FooterLink href="https://customer-support.iheartjane.com/s/">
              Help Center
            </FooterLink>
          </FooterColumn>

          <FooterColumn>
            <FooterHeader>Follow Us</FooterHeader>
            <FooterLink href="https://www.instagram.com/_iheartjane/">
              Instagram
            </FooterLink>
            <FooterLink href="https://www.facebook.com/iheartjanecom/">
              Facebook
            </FooterLink>
            <FooterLink href="https://www.linkedin.com/company/jane-technologies-inc/">
              LinkedIn
            </FooterLink>
            <FooterLink href="https://iheartjane.store/">Jane Gear</FooterLink>
          </FooterColumn>
          <FooterColumn>
            <FooterHeader>Download</FooterHeader>
            <FooterLink href="https://iheartjane.app.link/marketplace">
              iOS App
            </FooterLink>
          </FooterColumn>
        </FooterSectionLinks>

        <FooterSectionLogo width="100%" justifyContent="center">
          <Link to={paths.root()} aria-label="Jane" variant="minimal">
            <JaneLogo
              color="white"
              size={isTablet ? 'md' : 'sm'}
              variant="secondary"
            />{' '}
          </Link>
        </FooterSectionLogo>

        <FooterSectionLegal flexDirection="column" alignItems="center">
          <Flex flexWrap="wrap" justifyContent="center" width="100%">
            <FooterLink
              hasMarginRight
              minWidth="unset"
              target="_blank"
              to={paths.privacyPolicy()}
            >
              Privacy Policy
            </FooterLink>
            <FooterLink
              hasMarginRight
              minWidth="unset"
              target="_blank"
              to={paths.termsOfUse('customer')}
            >
              Terms of Service
            </FooterLink>
            <FooterLink
              hasMarginRight
              minWidth="unset"
              target="_blank"
              to={paths.accessibility()}
            >
              ADA
            </FooterLink>
            <FooterLink
              hasMarginRight
              minWidth="unset"
              to={paths.doNotSellMyInformation()}
            >
              Do Not Sell My Personal Information
            </FooterLink>
            <FooterLink href="https://status.iheartjane.com/" minWidth="unset">
              Status
            </FooterLink>
          </Flex>

          <Typography mb={16} color="grays-light">
            &copy; {new Date().getFullYear()} Jane Technologies, Inc.
          </Typography>
        </FooterSectionLegal>

        <FooterSectionSoc2>
          <Soc2Image />
        </FooterSectionSoc2>
      </FooterContainer>
    </Box>
  );
}
