import { AddressAutocomplete } from '@jane/shared-ecomm/components';
import { LocationDetector } from '@jane/shared-ecomm/util';
import type { Location } from '@jane/shared/models';
import { Box, Form, Modal } from '@jane/shared/reefer';

import Security from '../security';
import { Header } from './header';
import type { LocationModalScreen } from './index';

interface Props {
  appPartner: string;
  chosenAddress?: {
    label: string;
    value: string;
  };
  detecting: boolean;
  handleLocation: (location: Location) => void;
  setDetecting: (detecting: boolean) => void;
  setShowScreen: (screen: LocationModalScreen) => void;
}

const LOCATION_LABEL_ID = 'user-location';

const Manually = ({
  appPartner,
  detecting,
  handleLocation,
  setDetecting,
  setShowScreen,
}: Props) => {
  const onSubmit = (values) => {
    const address = values[LOCATION_LABEL_ID];

    if (address) {
      setDetecting(true);
      LocationDetector.getInstance()
        .geocode(address)
        .then(handleLocation, () => setShowScreen('error'));
    }
  };

  return (
    <Modal.Content overflowY="visible">
      <Header>Enter your location.</Header>
      <Form name={`${LOCATION_LABEL_ID}-form`} onSubmit={onSubmit}>
        <AddressAutocomplete
          autoFocus
          clearIcon
          isForm
          locationIcon
          name={LOCATION_LABEL_ID}
          placeholder="Address, city, or zip code"
        />

        <Box mt={24} mx="auto" width="100%">
          <Form.SubmitButton full loading={detecting} label="Confirm" />
        </Box>
      </Form>
      <Box mt={24}>
        <Security appPartner={appPartner} modal />
      </Box>
    </Modal.Content>
  );
};

export default Manually;
