import type { Location } from '@jane/shared/models';
import { Box, Button, Flex, Modal } from '@jane/shared/reefer';

import CTAButton from './ctaButton';
import { Header } from './header';

interface Props {
  setAskUserForLocation?: (arg: boolean) => void;
  setUserLocation: (arg: Location) => void;
  userLocation: Location;
}

const ConfirmLocation = ({
  setAskUserForLocation,
  setUserLocation,
  userLocation,
}: Props) => (
  <Modal.Content>
    <Flex alignItems="center" flexDirection="column">
      <Header>Your location is set to {userLocation.cityState}.</Header>
      <Box mb={24} width="100%">
        <CTAButton
          onClick={() => setAskUserForLocation && setAskUserForLocation(false)}
          type="submit"
          text="Use my location"
        />
      </Box>
      <Button
        full
        label="Change my location"
        mb={24}
        onClick={() => {
          setUserLocation({
            coordinates: undefined,
            cityState: undefined,
            hasResetLocation: true,
            zipcode: undefined,
          });
          setAskUserForLocation && setAskUserForLocation(true);
        }}
        variant="secondary"
      />
    </Flex>
  </Modal.Content>
);

export default ConfirmLocation;
