import styled from '@emotion/styled';
import type { FunctionComponent } from 'react';
import { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useQuery } from '@jane/shared-ecomm/hooks';
import {
  AuthenticationProvider,
  UserPreferencesProvider,
} from '@jane/shared-ecomm/providers';
import { MfaModal } from '@jane/shared/components';
import { MfaProvider, ScrollRestorationProvider } from '@jane/shared/providers';
import { ToastProvider, useMobileMediaQuery } from '@jane/shared/reefer';
import { PaginationCountProvider } from '@jane/shared/util';

import { PendingCartDrawer } from '../../components/cart/pendingCartDrawer/pendingCartDrawer';
import { useCustomerDispatch } from '../../customer/dispatch';
import { updateQueryPromoCode, updateTags } from '../../customer/redux/cart';
import { setPreviousPath } from '../../customer/redux/users';
import { useAuth } from '../../hooks/useAuth/useAuth';
import { useForceClientRefresh } from '../../hooks/useForceClientRefresh';
import { useScrollReset } from '../../hooks/useScrollReset';
import { IdentifyCustomer } from '../../tracking';
import { CartAlertBrandSpecialChangesProvider } from '../cartAlerts/cartAlertBrandSpecialChangesProvider';
import { Footer } from '../footer';
import Header from '../header';
import Modals from '../modals';
import ServerNotifications from '../notifications/server';
import { SkipLinks } from '../skipLinks';
import { ScrollPositionProvider } from '../storeDetail/scrollPositionProvider';

const AppContainer = styled.div({
  display: 'grid',
  gridAutoColumns: '100%',
  gridTemplate: `'header' auto 'main' 1fr 'footer' auto`,
  minHeight: '100vh',
});

const HeaderRow = styled.div({
  gridArea: 'header',
  position: 'sticky',
  top: 0,
  zIndex: 1002,
});

const MainRow = styled.div({
  gridArea: 'main',
});

const FooterRow = styled.div({
  gridArea: 'footer',
});

const Application: FunctionComponent = () => {
  useScrollReset();
  useForceClientRefresh();
  const location = useLocation();
  const authentication = useAuth();
  const query = useQuery();
  const previousPathRef = useRef(location.pathname);
  const dispatch = useCustomerDispatch();
  const isMobile = useMobileMediaQuery({});

  useEffect(() => {
    dispatch(updateTags(query));
    dispatch(updateQueryPromoCode(query['promo_code']));
  }, []);

  useEffect(() => {
    dispatch(setPreviousPath(previousPathRef.current));
    previousPathRef.current = location.pathname;
  }, [location.pathname, dispatch]);

  const isCheckoutPage = location.pathname.includes('checkout');
  const isInvoicePage = location.pathname.includes('invoice');
  const isHomePage = location.pathname === '/';

  const showHeader = !(isCheckoutPage || isInvoicePage);
  const showFooter = !(isCheckoutPage || isInvoicePage);

  return (
    <ToastProvider position={isMobile ? 'top-middle' : 'top-right'}>
      <PaginationCountProvider>
        <ScrollPositionProvider>
          <ScrollRestorationProvider isIframe={false}>
            <MfaProvider>
              <UserPreferencesProvider>
                <AuthenticationProvider value={{ ...authentication }}>
                  <CartAlertBrandSpecialChangesProvider>
                    <IdentifyCustomer app="default" queryParams={query} />
                    <Modals />
                    <ServerNotifications />
                    <PendingCartDrawer />
                    <MfaModal />
                    <SkipLinks />

                    <AppContainer>
                      <HeaderRow>
                        {showHeader && (
                          <Header
                            hideSearch={isHomePage}
                            variant={isHomePage ? 'dynamic' : 'static'}
                          />
                        )}
                      </HeaderRow>

                      <MainRow>
                        <Outlet />
                      </MainRow>

                      <FooterRow>{showFooter && <Footer />}</FooterRow>
                    </AppContainer>
                  </CartAlertBrandSpecialChangesProvider>
                </AuthenticationProvider>
              </UserPreferencesProvider>
            </MfaProvider>
          </ScrollRestorationProvider>
        </ScrollPositionProvider>
      </PaginationCountProvider>
    </ToastProvider>
  );
};

export default Application;
