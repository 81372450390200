import type { RefObject } from 'react';
import { useEffect } from 'react';

const useOutsideClickHandler = (ref: RefObject<any>, handler: () => void) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener('mousedown', listener);
    return () => {
      // Remove listener on unmount
      document.removeEventListener('mousedown', listener);
    };
  }, [ref]);
};

export { useOutsideClickHandler };
