/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const MenuFilled24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Menu filled 24">
      <path
        fill="#5E1FF0"
        fillRule="evenodd"
        d="M0 4a2 2 0 0 1 2-2h20a2 2 0 1 1 0 4H2a2 2 0 0 1-2-2Zm0 8a2 2 0 0 1 2-2h12a2 2 0 1 1 0 4H2a2 2 0 0 1-2-2Zm0 8a2 2 0 0 1 2-2h20a2 2 0 1 1 0 4H2a2 2 0 0 1-2-2Z"
        className="Fill-Main"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: MenuFilled24,
  sm: MenuFilled24,
  lg: MenuFilled24,
  xl: MenuFilled24,
  xxl: MenuFilled24,
  xxxl: MenuFilled24,
};

export const MenuFilledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'menu-filled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
