import { useNavigate } from 'react-router-dom';

import { Box, Card, Flex, Tag, Typography } from '@jane/shared/reefer';
import { getCategoryNameColor, titleize } from '@jane/shared/util';

import { JaneGoldTag } from '../janeGoldTag';
import { StarRating } from '../starRating';
import { EllipsesTypography, StyledCard } from './productCard.styles';
import type { ProductCardProps } from './productCard.types';
import { getPrices, priceIdToWeight } from './util';

export const ProductCard = ({
  brand,
  category,
  discountedPrice: discountedPriceProp,
  imgSrc,
  janeGoldTagLabel,
  kind,
  name,
  navigateOptions = {},
  numberOfRatings,
  onClick,
  price: priceProp,
  priceId,
  rating,
  specialLabel,
  to,
}: ProductCardProps) => {
  const navigate = useNavigate();
  const { price, discountedPrice } = getPrices(priceProp, discountedPriceProp);

  const handleClick = () => {
    onClick && onClick();
    to && navigate(to, navigateOptions);
  };

  return (
    <StyledCard
      onClick={handleClick}
      ariaLabel={`${name}-product-card`}
      width="100%"
      height="100%"
      border="grays-light"
    >
      <Flex
        flexWrap="wrap"
        gap={4}
        justifyContent="flex-end"
        position="absolute"
        p={8}
        width="100%"
        zIndex={99}
      >
        {janeGoldTagLabel && <JaneGoldTag label={janeGoldTagLabel} />}
        {specialLabel && (
          <Tag label={specialLabel} color="grays-white" background="info" />
        )}
      </Flex>

      <Card.Image
        src={imgSrc || ''}
        alt={`${name}-image`}
        ariaLabel={`${name}-image`}
        height="152px"
        sizes="card"
        responsive
      />

      <Card.Content>
        {category && (
          <Typography
            variant="mini-bold"
            color={getCategoryNameColor(category)}
          >
            {titleize(category)}
          </Typography>
        )}

        <EllipsesTypography variant="body-bold" lines={2} as="div">
          {name}
        </EllipsesTypography>
        <EllipsesTypography color="grays-mid" lines={1} as="div">
          {brand}
        </EllipsesTypography>

        <StarRating
          minimumRatings={5}
          numberOfRatings={numberOfRatings}
          rating={rating}
        />

        <Typography color="grays-mid">
          {kind.charAt(0).toUpperCase() + kind.slice(1)}
        </Typography>

        <Box bottom={14} right={16} position="absolute">
          <Flex>
            <Typography variant="body-bold" color="primary">
              {discountedPrice || price}/{priceIdToWeight[priceId] || 'ea'}
            </Typography>
            {discountedPrice && (
              <Typography as="span" color="grays-mid" strikeThrough ml={4}>
                {price}
              </Typography>
            )}
          </Flex>
        </Box>
      </Card.Content>
    </StyledCard>
  );
};
