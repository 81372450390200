import { matchRoutes, useLocation } from 'react-router-dom';

const PATHNAMES_WITH_PREFERENCES_BUTTON = [
  '/',
  '/brands/*',
  '/categories/:category',
  '/profile/*',
  '/products',
  '/products/:product_id/:slug',
  '/stores',
  '/stores/:store_id/:store_slug/products/:product_id/:product_slug',
  '/dispensaries/*',
  '/directory',
];

export const useShowUserPreferencesButton = () => {
  const location = useLocation();
  const userPreferencesPaths = PATHNAMES_WITH_PREFERENCES_BUTTON.map(
    (path) => ({
      path,
    })
  );
  const showUserPreferencesButton = !!matchRoutes(
    userPreferencesPaths,
    location
  );

  return showUserPreferencesButton;
};
