import { NavBarTapSection, trackCartClick } from '@jane/shared-ecomm/tracking';
import { CountDot } from '@jane/shared/components';
import { BagFilledIcon, Box } from '@jane/shared/reefer';
import type { ColorKey } from '@jane/shared/reefer';

import { useCustomerDispatch } from '../../customer/dispatch';
import { openCart } from '../../customer/redux/cart';

export interface HeaderCartProps {
  color?: ColorKey;
  onTrackTap: (navBarTap: NavBarTapSection) => void;
  productCount?: number;
}

const HeaderCart = ({
  color,
  productCount = 0,
  onTrackTap,
}: HeaderCartProps) => {
  const dispatch = useCustomerDispatch();

  const handleCartClick = () => {
    trackCartClick();
    onTrackTap(NavBarTapSection.Bag);
    dispatch(openCart());
  };
  return (
    <Box position="relative" data-testid="header-cart" m={12}>
      <BagFilledIcon
        color={color}
        onClick={handleCartClick}
        altText="Open Cart Drawer"
      />
      {productCount > 0 && (
        <CountDot
          ariaLabel="cart product count"
          onClick={handleCartClick}
          count={productCount}
        />
      )}
    </Box>
  );
};

export default HeaderCart;
