import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import type { ReeferTheme } from '@jane/shared/reefer';
import { mediaQueries, shadows, spacing } from '@jane/shared/reefer-emotion';

import {
  HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from '../../types/headerConstants';

interface MarketplaceHeaderContainerProps {
  backgroundColor?: 'white' | 'transparent';
}

interface CheckoutHeaderContainerProps {
  backgroundColor?: 'white' | 'transparent';
  children?: ReactNode;
  theme?: ReeferTheme;
}

export const StyledHeader = styled.header<MarketplaceHeaderContainerProps>(
  ({ backgroundColor, theme }) => ({
    boxShadow: backgroundColor === 'white' ? shadows.divider : 'none',
    background:
      backgroundColor === 'white' ? theme.colors.grays.white : 'transparent',
    width: '100%',
    ...spacing({ py: 8, px: 8 }),
    [mediaQueries.tablet('min')]: {
      ...spacing({ py: 16, px: 24 }),
    },
  })
);

export const CheckoutHeaderContainer = styled.header(
  { alignItems: 'center', display: 'flex', justifyContent: 'space-between' },
  spacing({ px: 8 }),
  {
    height: MOBILE_HEADER_HEIGHT,
    width: '100%',
    [mediaQueries.tablet('min')]: [
      spacing({ px: 24, py: 0 }),
      {
        height: HEADER_HEIGHT,
      },
    ],
  },
  ({ backgroundColor = 'white', theme }: CheckoutHeaderContainerProps) => ({
    boxShadow: backgroundColor === 'white' && shadows.divider,
    background: backgroundColor === 'white' && theme.colors.grays.white,
  })
);
