import type { FunctionComponent } from 'react';
import { shallowEqual } from 'react-redux';

import type { NavBarTapSection } from '@jane/shared-ecomm/tracking';
import type { ColorKey } from '@jane/shared/reefer';

import { useCustomerSelector } from '../../customer/selectors';
import { calcNumberOfCartItems } from '../../lib/cart';
import HeaderCart from './cart';

interface Props {
  color?: ColorKey;
  onTrackTap: (navBarTap: NavBarTapSection) => void;
}

const HeaderCartContainer: FunctionComponent<Props> = ({
  color,
  onTrackTap,
}) => {
  const { cart } = useCustomerSelector(
    (state) => ({
      cart: state.cart.cart,
    }),
    shallowEqual
  );

  return (
    <HeaderCart
      color={color}
      onTrackTap={onTrackTap}
      productCount={calcNumberOfCartItems(cart)}
    />
  );
};

export default HeaderCartContainer;
