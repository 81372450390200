import styled from '@emotion/styled';
import { useState } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import { UserPreferencesModal } from '@jane/shared-ecomm/components';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import type { StoreFulfillmentTypes } from '@jane/shared/models';
import {
  ChevronDownIcon,
  Flex,
  Link,
  useTabletMediaQuery,
} from '@jane/shared/reefer';

const StyledLink = styled(Link)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const getOnboardingButtonText = ({
  cityState,
  showFullfillmentType = true,
  storeFulfillmentType,
}: {
  cityState?: string;
  showFullfillmentType?: boolean;
  storeFulfillmentType: StoreFulfillmentTypes;
}) => {
  if (!cityState || !cityState?.length) {
    return 'Enter Your Location';
  }

  let fulfillmentText = 'Order';

  if (
    showFullfillmentType &&
    storeFulfillmentType &&
    storeFulfillmentType !== 'all'
  ) {
    fulfillmentText =
      storeFulfillmentType.charAt(0).toUpperCase() +
      storeFulfillmentType.slice(1);
  }

  return `${fulfillmentText} near ${cityState}`;
};

export const UserPreferencesButton = () => {
  const [showModal, setShowModal] = useState(false);
  const {
    userLocation: { cityState },
    userPreferences: { storeFulfillmentType },
  } = useUserPreferences();
  const isLargerThanMobile = useTabletMediaQuery({});
  const location = useLocation();
  const showFullfillmentType = !!matchRoutes([{ path: '/stores' }], location);

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-end"
      minWidth="100px"
      ml={isLargerThanMobile ? 'auto' : 0}
      mr={isLargerThanMobile ? 4 : 0}
      mt={isLargerThanMobile ? 0 : 8}
    >
      <StyledLink
        typography="body-bold"
        onClick={() => setShowModal(true)}
        variant="minimal"
        branded
      >
        {getOnboardingButtonText({
          cityState,
          storeFulfillmentType,
          showFullfillmentType,
        })}
      </StyledLink>
      <Flex>
        <ChevronDownIcon
          ml={4}
          size="sm"
          altText="open user preferences"
          onClick={() => setShowModal(true)}
        />
      </Flex>
      {showModal && (
        <UserPreferencesModal
          onCloseModal={() => setShowModal(false)}
          showModal={showModal}
        />
      )}
    </Flex>
  );
};
