import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';

import {
  CustomerGlobal,
  MediaQueryProvider,
  bootstrap,
  configureCustomerStore,
} from '@jane/monolith/marketplace';
import { config } from '@jane/shared/config';
import { asyncFlagProvider } from '@jane/shared/feature-flags';
import { EcommAppProvider } from '@jane/shared/providers';

import { App } from './app/App';

const store = configureCustomerStore();

const container = document.getElementById('app') as HTMLElement;
const root = createRoot(container);

(async () => {
  const FlagProvider = await asyncFlagProvider(config.launchDarklyClientID);
  await bootstrap(true);
  root.render(
    <EcommAppProvider appMode="default">
      <FlagProvider>
        <ReduxProvider store={store}>
          <CustomerGlobal />
          <MediaQueryProvider>
            <App />
          </MediaQueryProvider>
        </ReduxProvider>
      </FlagProvider>
    </EcommAppProvider>
  );
})();
