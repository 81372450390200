import { useQuery } from '@tanstack/react-query';
import isNull from 'lodash/isNull';

import { config } from '@jane/shared/config';
import { api } from '@jane/shared/data-access';

interface MapboxFeatures {
  center: number[];
}

const getCoordinates = (
  location: string | null
): Promise<{ features: MapboxFeatures[] }> =>
  api.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${location}.json?access_token=${config.mapboxAccessToken}`
  );

export const useGetCoordinates = (location: string | null) => {
  return useQuery({
    queryFn: () => getCoordinates(location),
    queryKey: ['searchLocation', location],
    enabled: !isNull(location),
  });
};
