import type { ProductLineage } from '@jane/shared/models';
import type { ColorKey } from '@jane/shared/reefer';

export const getCategoryNameColor = (category: ProductLineage): ColorKey => {
  switch (category) {
    case 'cbd':
      return 'palm';
    case 'sativa':
      return 'ember';
    case 'indica':
      return 'grape';
    case 'hybrid':
      return 'grays-dark';
    default:
      return 'grays-dark';
  }
};
