import type { ReactNode } from 'react';

import { useRequireAuthenticatedCustomer } from '../hooks/useRequireAuthenticated';

const RequiresAuthentication = ({ children }: { children?: ReactNode }) => {
  const { loading } = useRequireAuthenticatedCustomer();

  if (loading) {
    return null;
  }

  return <>{children}</>;
};

export default RequiresAuthentication;
