import { Outlet } from 'react-router-dom';

import { ApplicationGates } from './applicationGates';

export const MarketplaceApplicationGates = () => (
  <ApplicationGates>
    <Outlet />
  </ApplicationGates>
);

export default MarketplaceApplicationGates;
