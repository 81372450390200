import styled from '@emotion/styled';
import capitalize from 'lodash/capitalize';

import { Box, Flex, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import AverageRating from '../starRatings/averageRating';
import ReviewsCount from '../starRatings/reviewsCount';
import TypeBadge from '../typeBadge';

const Brand = ({ children }) => (
  <Typography
    color="grays-mid"
    as="span"
    css={{ '&::after': [spacing({ ml: 4 }), { content: '"•"' }] }}
    mr={4}
    variant="body"
  >
    {children}
  </Typography>
);

const SearchHitStyled = styled(Flex)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.grays.light}`,
  minHeight: 65,
  '@media (hover: hover)': {
    '&:hover': {
      backgroundColor: theme.colors.grays.ultralight,
    },
  },
}));

export const SearchHit = ({ children }) => (
  <SearchHitStyled px={8} py={12}>
    {children}
  </SearchHitStyled>
);

const LeftColumn = styled.div({ flex: 'auto' });
const RightColumn = styled(Flex)({ flex: 'auto' });

const Photo = styled.div<{ url: string }>(
  { borderRadius: 4 },
  ({ url, theme }) => ({
    height: 40,
    width: 40,
    backgroundImage: `url(${url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: theme.colors.grays.white,
    position: 'relative',
  })
);

interface Props {
  hit: any;
  hitType: 'store' | 'product';
  photoUrl: string;
}

const TypeAheadSearchHit = ({ hit, photoUrl, hitType }: Props) => (
  <SearchHit data-testid="typeahead-search-hit">
    <Box mr={12}>
      <Photo url={photoUrl} />
    </Box>
    <Flex grow flexDirection="column">
      <Flex justifyContent="space-between">
        <LeftColumn>
          <Typography variant="body-bold">{hit.name}</Typography>
          {hit.brand && <Brand>{hit.brand}</Brand>}
          <Typography as="span" color="grays-mid" variant="body">
            {hit.metro_area || hit.brand_subtype || capitalize(hit.kind)}
          </Typography>
        </LeftColumn>
        <RightColumn justifyContent="flex-end">
          {hitType === 'product' && (
            <Box width={80}>
              <TypeBadge dark small type={hit.type} />
            </Box>
          )}
          {hitType === 'store' && (
            <Flex flexDirection="column" alignItems="flex-end">
              <AverageRating rating={hit.rating} />
              <ReviewsCount count={hit.reviews_count} />
            </Flex>
          )}
        </RightColumn>
      </Flex>
    </Flex>
  </SearchHit>
);

export default TypeAheadSearchHit;
