import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import type { InfiniteHitsProvided, SearchState } from '@jane/search/types';
import { EventNames, track } from '@jane/shared-ecomm/tracking';
import { productDetailLink } from '@jane/shared/components';
import type { Product } from '@jane/shared/models';
import { useEcommApp } from '@jane/shared/providers';
import { productPhotoMetadata } from '@jane/shared/util';

import { useCustomerDispatch } from '../../customer/dispatch';
import { setProductDetailBreadcrumbs } from '../../customer/redux/search';
import { paths } from '../../lib/routes';
import TypeAheadSearchAllLink from './typeAheadSearchAllLink';
import TypeAheadSearchHit from './typeAheadSearchHit';
import { SearchHitsContainer } from './typeAheadStoreSearchResults';

interface Props {
  hits: InfiniteHitsProvided['hits'];
  indexName: string;
  searchState: SearchState;
  setSearchFocused: (arg: boolean) => void;
}

export const TypeAheadProductSearchResults: FunctionComponent<Props> = ({
  hits,
  searchState,
  setSearchFocused,
  indexName,
}) => {
  const dispatch = useCustomerDispatch();
  const { appMode } = useEcommApp();

  return (
    <SearchHitsContainer>
      {hits &&
        hits.map((hit) => {
          const product: Product = {
            ...hit,
            id: hit.objectID,
          } as Product;
          return (
            <Link
              key={product.id}
              onClick={() => {
                dispatch(setProductDetailBreadcrumbs(searchState));
                track({
                  event: EventNames.SearchedProducts,
                  query: searchState.query,
                  hits: hits.length,
                  typeahead: true,
                  filterCount: 0,
                  filters: [],
                  sort: undefined,
                  indexName,
                });
              }}
              to={productDetailLink({
                product,
                appMode,
                routeAppMode: paths.getAppMode(),
                routePartnerHostedConfig: paths.getPartnerHostedConfig(),
              })}
            >
              <TypeAheadSearchHit
                hit={product}
                photoUrl={productPhotoMetadata(product).url}
                hitType="product"
              />
            </Link>
          );
        })}

      <TypeAheadSearchAllLink
        linkTo={paths.products({ searchText: searchState.query })}
        hitType="products"
        searchQuery={searchState.query}
        setSearchFocused={setSearchFocused}
      />
    </SearchHitsContainer>
  );
};

export default TypeAheadProductSearchResults;
