import { QueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import {
  initAlgoliaInsights,
  useSetJaneDeviceId,
} from '@jane/monolith/marketplace';
import { initBraze } from '@jane/shared-ecomm/tracking';
import { initializeBranchIo } from '@jane/shared-ecomm/util';
import { JaneApplicationWrapper } from '@jane/shared/components';
import { config, defaultQueryClientConfig } from '@jane/shared/config';
import { initMixpanel, initTrackJS, loadTimezoneData } from '@jane/shared/util';

import { createRoutes } from './routes';

initBraze();
initTrackJS();
initMixpanel(config.mixpanelCustomerToken, {
  userSessionHost: config.mixpanelUserSessionApiHost,
  useLocalStorage: true,
});
loadTimezoneData();
initAlgoliaInsights();

export const App = () => {
  useSetJaneDeviceId();
  useEffect(() => {
    initializeBranchIo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryClient = useMemo(
    () => new QueryClient(defaultQueryClientConfig),
    []
  );

  const routesObject = createRoutes(queryClient);

  return (
    <JaneApplicationWrapper
      router={createBrowserRouter(routesObject)}
      queryClient={queryClient}
    />
  );
};
