/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Card24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Card 24">
      <g className="Vector">
        <path
          fill="#0E0E0E"
          d="m3.812 16.996.202 1.431a4 4 0 0 0 4.517 3.405l11.884-1.67a4 4 0 0 0 3.404-4.518l-.974-6.932a4.001 4.001 0 0 0-2.887-3.297c.027.19.042.386.042.585v1.61c.454.305.782.795.864 1.38l.974 6.932a2 2 0 0 1-1.702 2.26l-11.883 1.67a2 2 0 0 1-2.259-1.703L5.833 17H4c-.063 0-.125-.002-.188-.004Z"
        />
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          d="M16 4H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2ZM4 2a4 4 0 0 0-4 4v7a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4V6a4 4 0 0 0-4-4H4Z"
          clipRule="evenodd"
        />
        <path
          fill="#0E0E0E"
          d="M5 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-4-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7Z"
        />
      </g>
    </g>
  </svg>
);

const Card32 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <g className="Card 32">
      <g className="Vector">
        <path
          fill="#0E0E0E"
          d="m5.113 22.62.27 1.874c.413 2.862 3.126 4.857 6.06 4.454l15.938-2.185c2.934-.402 4.978-3.049 4.566-5.91l-1.306-9.07c-.307-2.126-1.881-3.773-3.873-4.315.037.25.057.505.057.766v2.105c.61.4 1.049 1.042 1.16 1.807l1.306 9.07c.206 1.431-.816 2.755-2.283 2.956l-15.939 2.185c-1.467.201-2.823-.796-3.03-2.227l-.216-1.504H5.365a7.06 7.06 0 0 1-.252-.005Z"
        />
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          d="M21.46 5.617H5.365c-1.482 0-2.683 1.171-2.683 2.617v9.159c0 1.445 1.201 2.616 2.683 2.616H21.46c1.481 0 2.682-1.171 2.682-2.616v-9.16c0-1.445-1.2-2.616-2.682-2.616ZM5.365 3C2.402 3 0 5.343 0 8.234v9.159c0 2.89 2.402 5.233 5.365 5.233H21.46c2.963 0 5.365-2.343 5.365-5.233v-9.16C26.825 5.344 24.423 3 21.46 3H5.365Z"
          clipRule="evenodd"
        />
        <path
          fill="#0E0E0E"
          d="M6.706 16.084c0 .723-.6 1.309-1.341 1.309-.74 0-1.341-.586-1.341-1.309 0-.722.6-1.308 1.341-1.308.74 0 1.341.586 1.341 1.308Zm4.024 0c0 .723-.6 1.309-1.341 1.309s-1.342-.586-1.342-1.309c0-.722.601-1.308 1.342-1.308.74 0 1.341.586 1.341 1.308Zm4.024 0c0 .723-.6 1.309-1.341 1.309s-1.342-.586-1.342-1.309c0-.722.6-1.308 1.341-1.308s1.342.586 1.342 1.308Zm4.023 0c0 .723-.6 1.309-1.34 1.309-.742 0-1.342-.586-1.342-1.309 0-.722.6-1.308 1.341-1.308.74 0 1.341.586 1.341 1.308Zm4.024 0c0 .723-.6 1.309-1.341 1.309-.74 0-1.341-.586-1.341-1.309 0-.722.6-1.308 1.34-1.308.742 0 1.342.586 1.342 1.308Zm-5.365-6.542c0-.723.6-1.308 1.341-1.308h2.683c.74 0 1.341.585 1.341 1.308v1.309c0 .722-.6 1.308-1.341 1.308h-2.683c-.74 0-1.34-.586-1.34-1.309V9.543Z"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Card24,
  sm: Card24,
  lg: Card32,
  xl: Card32,
  xxl: Card32,
  xxxl: Card32,
};

export const CardIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'card-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
