import { DismissFilledIcon, Flex, Typography } from '@jane/shared/reefer';
import { pluralize } from '@jane/shared/util';

import type { PageHeaderProps } from '../pageHeader';
import { PageHeader } from '../pageHeader';

export interface SearchPageHeaderProps
  extends Pick<PageHeaderProps, 'breadcrumbCurrent' | 'breadcrumbs' | 'title'> {
  onClearSearch: () => void;
  searchCount?: number;
  searchText?: string | null;
  searchType?: 'product' | 'brand' | string;
  userCityState?: string;
}
export const SearchPageHeader = ({
  breadcrumbCurrent,
  breadcrumbs,
  onClearSearch,
  searchCount,
  searchText = '',
  searchType = 'product',
  title,
  userCityState,
}: SearchPageHeaderProps) => (
  <PageHeader
    breadcrumbCurrent={breadcrumbCurrent}
    breadcrumbs={breadcrumbs}
    subtitle={
      searchCount
        ? `Found ${pluralize({ number: searchCount, noun: searchType })}${
            userCityState ? ` near ${userCityState}` : ''
          }`
        : undefined
    }
    title={
      searchText ? (
        <Flex alignItems="center">
          <Typography as="h1" branded variant="header-bold" mr={8}>
            Search for &#34;{searchText}&#34;
          </Typography>
          <DismissFilledIcon
            onClick={onClearSearch}
            altText="Clear search"
            size="sm"
          />
        </Flex>
      ) : (
        title
      )
    }
  />
);
