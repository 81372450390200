import { Button } from '@jane/shared/reefer';

interface Props {
  detecting?: boolean;
  onClick?: () => void;
  text: string;
  type?: 'submit' | 'reset' | 'button';
}

const CTAButton = ({
  detecting = false,
  onClick,
  text,
  type = 'button',
}: Props) => (
  <Button
    full
    disabled={detecting}
    loading={detecting}
    onClick={onClick}
    type={type}
    label={text}
    branded
  />
);

export default CTAButton;
