import type { QueryClient } from '@tanstack/react-query';
import type { RouteObject } from 'react-router-dom';

import { EnsureNotLoggedInContainer } from '@jane/monolith/marketplace';
import { Loader } from '@jane/shared/components';
import { load } from '@jane/shared/util';

const Login = Loader(load(() => import('../features/Login')));

const ForgotPassword = Loader(load(() => import('../features/ForgotPassword')));
const ResetPassword = Loader(load(() => import('../features/ResetPassword')));
const UnlockAccount = Loader(load(() => import('../features/UnlockAccount')));

export const authRoutes = (queryClient: QueryClient): RouteObject[] => [
  {
    path: 'login',
    element: (
      <EnsureNotLoggedInContainer>
        <Login />
      </EnsureNotLoggedInContainer>
    ),
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: 'reset-password/:token',
    element: <ResetPassword />,
  },
  {
    path: 'unlock-account/:token',
    element: <UnlockAccount />,
  },
];
