import styled from '@emotion/styled';
import capitalize from 'lodash/capitalize';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import {
  connectInfiniteHits,
  connectStateResults,
} from '@jane/search/data-access';
import type {
  InfiniteHitsProvided,
  SearchResults,
  SearchState,
} from '@jane/search/types';
import { userLocationToParams } from '@jane/search/util';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { Flex, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { paths } from '../../lib/routes';
import { SearchHit } from './typeAheadSearchHit';

const SearchAllProductsLink = styled.button();
const POPULAR_SEARCHES = {
  products: ['Flower', 'Vape', 'Edible'],
  dispensaries: ['Daily deals', 'Accessible', 'Accepts credit cards'],
};

const PopularSearchPill = styled(Link)(() => [
  spacing({ mr: 8, px: 12, py: 8 }),
  ({ theme }) => ({
    backgroundColor: theme.colors.brand.purple.dark,
    borderRadius: theme.borderRadius.lg,
    minWidth: 40,
  }),
]);

type HitType = 'dispensaries' | 'products';

interface Props {
  hideResultsDropdown: () => void;
  hitType: HitType;
  hits?: InfiniteHitsProvided['hits'];
  nbHits?: number;
  renderSearchHits: (arg: any) => ReactNode;
  searchResults?: SearchResults;
  searchState?: SearchState;
}

export const TypeAheadSearchResultsSection = ({
  searchResults,
  hits,
  searchState,
  renderSearchHits,
  hitType,
  hideResultsDropdown,
}: Props) => {
  const {
    userLocation: { cityState, countryCode },
  } = useUserPreferences();
  const userLocationPath = userLocationToParams(cityState, countryCode);

  return (
    <Flex flexDirection="column">
      <Flex
        background="grays-ultralight"
        alignItems="flex-start"
        py={16}
        pr={64}
        pl={16}
      >
        <Typography variant="body-bold">{capitalize(hitType)}</Typography>
      </Flex>
      {searchState && searchState.query ? (
        searchResults &&
        searchResults.nbHits > 0 &&
        searchState.query.trim() !== '' ? (
          renderSearchHits({ hits, searchState })
        ) : (
          <SearchHit>
            <SearchAllProductsLink>
              <Typography variant="body">{`No ${hitType} matched "${searchState.query}"`}</Typography>
            </SearchAllProductsLink>
          </SearchHit>
        )
      ) : (
        <Flex p={8} flexDirection="column">
          <Typography variant="body">Popular Searches</Typography>
          <Flex my={16}>
            {POPULAR_SEARCHES[hitType].map((searchTerm) => {
              const dispensaryQuery = {
                filters: searchTerm.includes('deals')
                  ? {
                      current_deals: [searchTerm],
                    }
                  : {
                      store_options: [
                        searchTerm.includes('credit')
                          ? 'credit'
                          : searchTerm.toLowerCase(),
                      ],
                    },
              };
              return (
                <PopularSearchPill
                  key={searchTerm}
                  to={
                    hitType === 'dispensaries'
                      ? userLocationPath
                        ? paths.dispensaries({
                            country: userLocationPath.country,
                            state: userLocationPath.state,
                            city: userLocationPath.city,
                            query: dispensaryQuery,
                          })
                        : paths.dispensaries({
                            query: dispensaryQuery,
                          })
                      : paths.products({
                          filters: {
                            root_types: [searchTerm.toLowerCase()],
                          },
                        })
                  }
                  onClick={hideResultsDropdown}
                >
                  <Typography color="text-inverse" variant="body">
                    {searchTerm}
                  </Typography>
                </PopularSearchPill>
              );
            })}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default connectInfiniteHits(
  connectStateResults(TypeAheadSearchResultsSection)
);
