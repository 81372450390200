import type { QueryClient } from '@tanstack/react-query';
import type { RouteObject } from 'react-router-dom';

import { RequiresAuthentication } from '@jane/monolith/marketplace';
import { Loader } from '@jane/shared/components';
import { load } from '@jane/shared/util';

const ProductSearch = Loader(load(() => import('../features/ProductSearch')));
const ProductDetail = Loader(
  load(() => import('../features/ProductDetail')),
  'menu-product-detail'
);
const ProductReview = Loader(load(() => import('../features/ProductReview')));

export const productRoutes = (queryClient: QueryClient): RouteObject[] => [
  { path: 'products', element: <ProductSearch /> },
  { path: 'products/:product_id/:slug', element: <ProductDetail /> },
  {
    path: 'products/:product_id/:slug/reviews/new',
    element: (
      <RequiresAuthentication>
        <ProductReview />
      </RequiresAuthentication>
    ),
  },
];
