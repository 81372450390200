import type { QueryClient } from '@tanstack/react-query';
import type { FunctionComponent } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import {
  Application,
  CustomerAppContainer,
  MarketplaceApplicationGates,
} from '@jane/monolith/marketplace';

import { authRoutes } from './routing/authRoutes';
import { gatedBaseRoutes, nonGatedBaseRoutes } from './routing/baseRoutes';
import { brandsRoutes } from './routing/brandsRoutes';
import { cartRoutes } from './routing/cartRoutes';
import { dispensariesRoutes } from './routing/dispensariesRoutes';
import { productRoutes } from './routing/productRoutes';
import { profileRoutes } from './routing/profileRoutes';
import { storeRoutes } from './routing/storeRoutes';

const AppRoot: FunctionComponent = () => (
  <CustomerAppContainer>
    <Application />
  </CustomerAppContainer>
);

const gatedRoutes = (queryClient: QueryClient): RouteObject => ({
  element: <MarketplaceApplicationGates />,
  children: [
    ...authRoutes(queryClient),
    ...brandsRoutes(queryClient),
    ...cartRoutes(queryClient),
    ...dispensariesRoutes(queryClient),
    ...gatedBaseRoutes(queryClient),
    ...productRoutes(queryClient),
    ...profileRoutes(queryClient),
    ...storeRoutes(queryClient),
  ],
});

export const createRoutes = (queryClient: QueryClient): RouteObject[] => {
  return [
    { element: <AppRoot />, children: [gatedRoutes(queryClient)] },
    {
      element: <AppRoot />,
      children: [...nonGatedBaseRoutes(queryClient)],
    },
    {
      element: <Navigate to="/" />,
      path: '*',
    },
  ];
};
