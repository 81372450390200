import type { QueryClient } from '@tanstack/react-query';
import type { RouteObject } from 'react-router-dom';

import { Loader } from '@jane/shared/components';
import { load } from '@jane/shared/util';

const Dispensaries = Loader(load(() => import('../features/Dispensaries')));

export const dispensariesRoutes = (queryClient: QueryClient): RouteObject[] => [
  { element: <Dispensaries />, path: 'dispensaries' },
  {
    path: 'dispensaries/:country/:state?/:city?',
    element: <Dispensaries />,
  },
  {
    path: 'dispensaries/coordinates/:lat/:long/:zoom',
    element: <Dispensaries />,
  },
];
